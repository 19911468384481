import type { Dict } from '@chakra-ui/utils';
import { ComponentState } from 'ts/common/constants/theme/common';

type ResponsiveStyleParam = TemplateStringsArray | string;

export function convertHexToRgba(hex: string, alpha = 1): string {
    const [, r1, r2, g1, g2, b1, b2] = Array.from(hex);
    const blue = parseInt(`${b1}${b2}`, 16);
    const green = parseInt(`${g1}${g2}`, 16);
    const red = parseInt(`${r1}${r2}`, 16);

    if (alpha > 1) {
        alpha = 1;
    } else if (alpha < 0) {
        alpha = 0;
    }

    return `rgba(${red},${green},${blue},${alpha})`;
}

export const navy300 = '#1280BE';
export const navy600 = '#0C547D'; // This is the "primary" color used for Primary Buttons, for example.
export const navy700 = '#002B4C';
export const gray300 = '#BCC8CD';
export const blue400 = '#49BDE4';

const breakpoints = {
    base: '0',
    sm: '480',
    md: '768',
    lg: '1200',
    xl: '1900',
    '2xl': '2400'
};

const colors = {
    blue: {
        100: '#D3EFF8',
        200: '#A7DFF1',
        300: '#7BCEEA',
        400: '#49BDE4',
        500: '#23AEDC',
        600: '#1C8BB0',
        700: '#156884'
    },
    navy: {
        100: '#69ACC3',
        200: '#4797B2',
        300: '#1280BE',
        400: '#2A5969',
        500: '#1B3A45',
        600: '#002B4C',
        700: '#00213B'
    },
    green: {
        100: '#CFEACF',
        200: '#ACDAAC',
        300: '#89CA89',
        400: '#66BA66',
        500: '#4AA34A',
        600: '#3A803A',
        700: '#2A5D2A'
    },
    orange: {
        100: '#FDE19C',
        200: '#FDD269',
        300: '#FCC337',
        400: '#FBB405',
        500: '#CA9003',
        600: '#986D02',
        700: '#654902'
    },
    red: {
        100: '#FFE9EA',
        200: '#FFB6B9',
        300: '#FF8389',
        400: '#FF5058',
        500: '#C01D0E',
        600: '#E9000B',
        700: '#B60008'
    },
    gray: {
        100: '#F6F8F9',
        200: '#D9E0E3',
        300: '#BCC8CD',
        400: '#9FB0B7',
        500: '#8298A1',
        600: '#677F89',
        700: '#51646C'
    },
    neutral: {
        200: '#EDF1F3',
        400: '#607780',
        500: '#344146',
        600: '#141617'
    },
    boxShadow: '0 4px 16px rgba(159, 176, 183, 0.3)'
};

const { md, lg, xl } = breakpoints;
const responsive = {
    extraLargeScreenOnly: (rules: ResponsiveStyleParam) => `
  @media only screen and (min-width: ${Number(xl) + 1}px) {
      ${rules}
  }
`,
    largeScreenDown: (rules: ResponsiveStyleParam) => `
  @media only screen and (max-width: ${xl}px) {
      ${rules}
  }
`,
    largeScreenOnly: (rules: ResponsiveStyleParam) => `
  @media only screen and (min-width: ${Number(lg) + 1}px) and (max-width: ${xl}px) {
      ${rules}
  }
`,
    largeScreenUp: (rules: ResponsiveStyleParam) => `
  @media only screen and (min-width: ${Number(lg) + 1}px) {
      ${rules}
  }
`,
    mediumScreenDown: (rules: ResponsiveStyleParam) => `
  @media only screen and (max-width: ${lg}px) {
      ${rules}
  }
`,
    mediumScreenOnly: (rules: ResponsiveStyleParam) => `
  @media only screen and (min-width: ${Number(md) + 1}px) and (max-width: ${lg}px) {
      ${rules}
  }
`,
    mediumScreenUp: (rules: ResponsiveStyleParam) => `
  @media only screen and (min-width: ${Number(md) + 1}px) {
      ${rules}
  }
`,
    smallScreenOnly: (rules: ResponsiveStyleParam) => `
  @media only screen and (max-width: ${md}px) {
      ${rules}
  }
`
};

const fonts = {
    body: 'azo-sans-web, Helvetica, Arial, sans-serif',
    heading: 'azo-sans-web, Helvetica, Arial, sans-serif',
    mono: 'azo-sans-web, Helvetica, Arial, sans-serif'
};

const components = {
    Button: {
        baseStyle: {
            width: 'auto'
        },
        variants: {
            primary: {
                default: {
                    backgroundColor: colors.blue[400],
                    borderColor: colors.blue[400],
                    color: '#FFFFFF'
                },
                hover: {
                    backgroundColor: colors.blue[700],
                    borderColor: colors.blue[700],
                    color: '#FFFFFF'
                },
                loaded: {
                    backgroundColor: colors.green[500],
                    borderColor: colors.green[500],
                    color: '#FFFFFF'
                },
                loading: {
                    backgroundColor: colors.blue[700],
                    borderColor: colors.blue[700]
                }
            },
            dismissive: {
                default: {
                    backgroundColor: '#FFFFFF',
                    borderColor: 'transparent',
                    color: colors.gray[600]
                },
                hover: {
                    backgroundColor: colors.gray[100],
                    borderColor: 'transparent',
                    color: colors.gray[600]
                },
                loaded: {
                    backgroundColor: colors.green[500],
                    borderColor: colors.green[500],
                    color: '#FFFFFF'
                },
                loading: {
                    backgroundColor: colors.gray[100]
                }
            }
        }
    },
    Checkbox: {
        customStyle: {
            backgroundColor: colors.gray[100],
            borderColor: colors.gray[400],
            checkColor: colors.blue[400],
            checkWidth: '8px',
            width: '16px',
            [ComponentState.Disabled]: {
                backgroundColor: colors.gray[100],
                borderColor: colors.gray[200],
                checkColor: colors.blue[100]
            }
        }
    },
    DatePicker: {
        baseStyle: {
            fontFamily: fonts.heading,
            backgroundColor: navy600,
            headerBackgroundColor: 'white',
            color: colors.neutral[600],
            disabledColor: '#cccccc',
            borderColor: colors.gray[200],
            dayColor: colors.gray[700],
            dayDisabledColor: colors.gray[300],
            dayHeaderColor: colors.gray[500],
            monthColor: colors.gray[600],
            monthDisabledColor: colors.gray[300],
            navigationButtonColor: colors.gray[300],
            rangeBackgroundColor: colors.blue[100],
            rangeColor: colors.blue[700],
            selectedColor: colors.blue[300]
        }
    },
    Form: {
        baseStyle: {
            requiredIndicator: {
                color: 'inherit',
                margin: 0
            }
        }
    },
    FormErrorMessage: {
        baseStyle: {
            color: colors.red[300],
            fontWeight: 300
        }
    },
    FormHelperText: {
        baseStyle: {
            fontWeight: 300
        }
    },
    FormLabel: {
        baseStyle: {
            fontSize: 14,
            lineHeight: '25px',
            color: colors.navy[400]
        },
        sizes: {
            lg: {
                fontSize: 16
            }
        }
    },
    Input: {
        baseStyle: {
            fontFamily: fonts.heading,
            field: {
                fontSize: '14px'
            }
        }
    },
    Select: {
        baseStyle: {
            field: {
                fontSize: '14px'
            }
        }
    },
    Radio: {
        baseStyle: {
            size: 'lg',
            spacing: '16px',
            color: navy600,
            container: {
                alignItems: 'flex-start'
            },
            _focus: {
                boxShadow: 'none'
            },
            _focusVisible: {
                boxShadow: 'outline'
            },
            control: {
                width: '16px',
                height: '16px',
                background: 'white',
                borderColor: colors.gray[400],
                _checked: {
                    background: 'white',
                    color: navy600,
                    borderColor: navy600,
                    '&:hover': {
                        background: 'white',
                        color: navy600
                    },
                    '&::before': {
                        height: '10px',
                        width: '10px',
                        background: navy600
                    }
                },
                _disabled: {
                    background: colors.gray[100],
                    borderColor: colors.gray[200],
                    '&::before': {
                        background: colors.gray[300]
                    }
                }
            }
        },
        variants: {
            fullWidth: {
                label: {
                    width: '100%'
                }
            }
        }
    },
    Text: {
        baseStyle: {
            fontFamily: fonts.heading
        },
        variants: {
            error: {
                color: 'red.400'
            }
        }
    },
    Tooltip: {
        baseStyle: {
            background: 'navy.700',
            borderRadius: '8px',
            color: 'white',
            fontSize: '12px',
            lineHeight: '12px',
            fontWeight: 300,
            padding: '6px'
        },
        variants: {
            bold: {
                textTransform: 'uppercase',
                fontWeight: 500,
                padding: '8px',
                letterSpacing: '1.4px'
            },
            light: {
                '--popper-arrow-bg': 'white',
                background: 'white',
                color: 'gray.700'
            }
        }
    }
};

const getTheme = (): Dict => {
    const theme: Dict = {
        colors,
        fonts,
        fontColor: {
            default: 'inherit'
        },
        styles: {
            global: {
                body: {
                    color: 'gray.600',
                    fontFamily: null,
                    lineHeight: null,
                    transition: null
                },
                input: {
                    fontFamily: fonts.body,
                    color: 'gray.600'
                },
                select: {
                    fontFamily: fonts.body,
                    color: 'gray.600'
                }
            }
        },
        components,
        responsive
    };

    return theme;
};

export default getTheme;

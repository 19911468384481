import React, { useEffect, useRef, useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    CloseButton,
    Flex,
    Modal,
    ModalBody,
    ModalContent,
    ModalHeader,
    ModalOverlay,
    Text,
    useTheme
} from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';
import ModernButton from 'ts/studio/common/ModernButton';

const SVG_VIEWBOX = '0 0 400 256';

interface SignatureModalProps {
    isOpen: boolean;
    isClientThemed?: boolean;
    onClose: () => void;
    onAcceptAndSign: (signaturePaths: string, svgViewbox: string) => void;
    translationKeyBase?: string;
}

const SignatureModal: React.FC<SignatureModalProps> = ({
    isOpen,
    isClientThemed = true,
    onClose,
    onAcceptAndSign,
    translationKeyBase = 'signatureModal'
}) => {
    const [signature, setSignature] = useState('');
    const [isDrawing, setIsDrawing] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const svgRef = useRef<SVGSVGElement>(null);
    const t = useTranslate(translationKeyBase);
    const theme = useTheme();
    const isAcceptButtonDisabled = !signature || !isChecked;

    const getCoordinates = (
        event: React.MouseEvent<SVGSVGElement> | React.TouchEvent<SVGSVGElement>
    ) => {
        if (!svgRef.current) {
            return '';
        }

        const point = svgRef.current.createSVGPoint();

        if ('touches' in event.nativeEvent) {
            const touchEvent = event.nativeEvent as TouchEvent;
            point.x = touchEvent.targetTouches[0].clientX;
            point.y = touchEvent.targetTouches[0].clientY;
        } else {
            const mouseEvent = event.nativeEvent as MouseEvent;
            point.x = mouseEvent.clientX;
            point.y = mouseEvent.clientY;
        }

        // Transform the point from screen coordinates to SVG coordinates
        const ctm = svgRef.current.getScreenCTM();

        if (ctm) {
            const transformedPoint = point.matrixTransform(ctm.inverse());
            return `${transformedPoint.x},${transformedPoint.y}`;
        }

        return '';
    };

    const handleMouseDown = (
        event: React.MouseEvent<SVGSVGElement> | React.TouchEvent<SVGSVGElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDrawing(true);
        const coords = getCoordinates(event);

        if (coords) {
            setSignature((prev) => `${prev} M${coords}`);
        }
    };

    const handleMouseMove = (
        event: React.MouseEvent<SVGSVGElement> | React.TouchEvent<SVGSVGElement>
    ) => {
        if (!isDrawing) {
            return;
        }

        event.preventDefault();
        event.stopPropagation();
        const coords = getCoordinates(event);

        if (coords) {
            setSignature((prev) => `${prev}L${coords}`);
        }
    };

    const handleMouseUp = (
        event: React.MouseEvent<SVGSVGElement> | React.TouchEvent<SVGSVGElement>
    ) => {
        event.preventDefault();
        event.stopPropagation();
        setIsDrawing(false);
    };

    // Add event listeners to handle mouse leaving the SVG area
    useEffect(() => {
        const svg = svgRef.current;

        if (!svg) {
            return;
        }

        const handleMouseLeave = () => {
            setIsDrawing(false);
        };

        svg.addEventListener('mouseleave', handleMouseLeave);

        return () => {
            svg.removeEventListener('mouseleave', handleMouseLeave);
        };
    }, []);

    const clearSignature = () => {
        setSignature('');
    };

    const handleAcceptAndSign = () => {
        onAcceptAndSign(signature, SVG_VIEWBOX);
        onClose();
    };

    const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setIsChecked(e.target.checked);
    };

    const ButtonComponent = isClientThemed ? Button : ModernButton;

    return (
        <Modal isOpen={isOpen} onClose={onClose} size="xl">
            <ModalOverlay />
            <ModalContent borderRadius="16px">
                <ModalHeader
                    color="brand.primary"
                    fontSize="20px"
                    fontWeight="600"
                    fontFamily="open-sans"
                    paddingY="24px"
                    paddingX="56px"
                    borderBottom="none"
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    {t('signature')}
                    <CloseButton
                        onClick={onClose}
                        border="none"
                        color="gray.500"
                        _hover={{ color: 'brand.primary' }}
                        sx={{
                            '&:focus': {
                                color: 'gray.500'
                            }
                        }}
                        cursor="pointer"
                    />
                </ModalHeader>
                <ModalBody
                    paddingTop="0"
                    paddingInlineStart="24px"
                    paddingInlineEnd="24px"
                    paddingBottom="32px"
                >
                    <Box position="relative">
                        <Box
                            data-testid="signature-svg"
                            as="svg"
                            width="100%"
                            height="256px"
                            border="1px solid #E2E2E2"
                            borderRadius="0"
                            ref={svgRef}
                            viewBox={SVG_VIEWBOX}
                            onMouseDown={handleMouseDown}
                            onMouseMove={handleMouseMove}
                            onMouseUp={handleMouseUp}
                            onMouseOut={handleMouseUp}
                            onTouchStart={handleMouseDown}
                            onTouchMove={handleMouseMove}
                            onTouchEnd={handleMouseUp}
                            style={{ touchAction: 'none' }}
                            backgroundColor={
                                theme.isLightColorScheme === false ? '#B3C1C6' : '#F9F9F9'
                            }
                            position="relative"
                        >
                            <line
                                x1="15"
                                y1="241"
                                x2="385"
                                y2="241"
                                stroke={
                                    theme.isLightColorScheme === false
                                        ? theme.colors.gray[200]
                                        : theme.colors.gray[300]
                                }
                                strokeWidth="2"
                            />
                            <path
                                d={signature}
                                fill="none"
                                stroke="black"
                                strokeWidth="2"
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                pointerEvents="none"
                            />
                        </Box>
                        <Button
                            position="absolute"
                            top="16px"
                            right="16px"
                            onClick={clearSignature}
                            variant="ghost"
                            color={
                                theme.isLightColorScheme === false
                                    ? theme.colors.gray[200]
                                    : theme.colors.gray[300]
                            }
                            fontSize="14px"
                            height="auto"
                            padding="0"
                            _hover={{ bg: 'transparent', color: '#4A154B' }}
                            cursor="pointer"
                        >
                            {t('clear')}
                        </Button>
                    </Box>
                    <Checkbox
                        marginTop="24px"
                        spacing="12px"
                        isChecked={isChecked}
                        onChange={handleCheckboxChange}
                        sx={{
                            '[class*="chakra-checkbox__control"]': {
                                bg: 'white',
                                _checked: {
                                    bg: 'brand.primary',
                                    borderColor: 'brand.primary'
                                }
                            }
                        }}
                    >
                        <Text fontSize="14px">{t('acceptAndSignDescription')}</Text>
                    </Checkbox>
                    <Flex gap="16px" marginTop="32px">
                        <ButtonComponent onClick={onClose} variant="outline primary" width="100%">
                            {t('cancel')}
                        </ButtonComponent>
                        <ButtonComponent
                            onClick={handleAcceptAndSign}
                            variant="primary"
                            width="100%"
                            isDisabled={isAcceptButtonDisabled}
                            cursor={isAcceptButtonDisabled ? 'not-allowed' : 'pointer'}
                        >
                            {t('acceptAndSign')}
                        </ButtonComponent>
                    </Flex>
                </ModalBody>
            </ModalContent>
        </Modal>
    );
};

export default SignatureModal;

import type { DeviceType, IFocalPointImageValues, IPoint } from 'ts/common/types';

export const computeFocalPointImageValues = (
    containerWidth: number,
    containerHeight: number,
    imageWidth: number,
    imageHeight: number,
    focalPoint: IPoint
): IFocalPointImageValues => {
    const containerAspectRatio = containerWidth / containerHeight;
    const focalPointPercentageX = focalPoint.x / 100;
    const focalPointPercentageY = focalPoint.y / 100;
    const imageAspectRatio = imageWidth / imageHeight;
    const isImageWiderThanContainer = imageAspectRatio > containerAspectRatio;
    let width: number;
    let height: number;

    if (isImageWiderThanContainer) {
        // Image is wider than the container
        // therefore match heights
        width = containerHeight * imageAspectRatio;
        height = containerHeight;
    } else {
        // Image is skinnier than the container
        // therefore match widths
        width = containerWidth;
        height = containerWidth / imageAspectRatio;
    }

    width = Math.round(width);
    height = Math.round(height);

    // Compute background position
    let offsetX = containerWidth / 2 - focalPointPercentageX * width;
    let offsetY = containerHeight / 2 - focalPointPercentageY * height;

    // Extending too far in quadrant II
    if (offsetX < -1 * (width - containerWidth)) {
        offsetX = -1 * (width - containerWidth);
    }

    if (offsetY < -1 * (height - containerHeight)) {
        offsetY = -1 * (height - containerHeight);
    }

    // Extending too far in quadrant IV
    offsetX = offsetX > 0 ? 0 : offsetX;
    offsetY = offsetY > 0 ? 0 : offsetY;

    offsetX = Math.round(offsetX);
    offsetY = Math.round(offsetY);

    return {
        height: height,
        width: width,
        offsetX: offsetX,
        offsetY: offsetY
    };
};

export function* createIdGenerator(prefix: string) {
    let count = 0;

    while (true) {
        yield `${prefix}-${count}`;
        count++;
    }
}

export function formatForTestId(input?: Nullable<string>) {
    return input
        ?.replace(/[^0-9A-Za-z\.& ]/g, '')
        .replace(/ /g, '-')
        .toLowerCase();
}

export const getRandomEntry = <T>(entries: T[]) =>
    entries[Math.floor(Math.random() * entries.length)];

export const isRoundProduct = (boundsName?: string) => {
    const roundCropBoundsNames: (string | undefined)[] = ['Button', 'Round Magnet'];

    return roundCropBoundsNames.includes(boundsName);
};

export const noop = () => {
    return;
};

export const asyncNoop = async () => {
    return;
};

export const numberFormat = (
    number: number | string,
    decimals: number,
    decimalPoint?: string,
    thousandsSeparator?: string
): string => {
    const wholeNumber = String(number).replace(',', '').replace(' ', '');
    const baseNumber = isFinite(+wholeNumber) ? +wholeNumber : 0;
    const precision = isFinite(+decimals) ? Math.abs(decimals) : 0;
    const separator = typeof thousandsSeparator === 'undefined' ? ',' : thousandsSeparator;
    const decimalCharacter = typeof decimalPoint === 'undefined' ? '.' : decimalPoint;
    const wholeNumberSplit = String(Math.round(baseNumber)).split('.');

    if (wholeNumberSplit[0].length > 3) {
        wholeNumberSplit[0] = wholeNumberSplit[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, separator);
    }

    if ((wholeNumberSplit[1] || '').length < precision) {
        wholeNumberSplit[1] = wholeNumberSplit[1] || '';
        wholeNumberSplit[1] += new Array(precision - wholeNumberSplit[1].length + 1).join('0');
    }

    return wholeNumberSplit.join(decimalCharacter);
};

export const shortFormatNumber = (number: number): string => {
    // Returns the shortened format of a given number as a string

    let baseNumber = Number(numberFormat(number, 1, '.', ''));

    if (Math.abs(baseNumber) >= 1000 && Math.abs(baseNumber) < 100000) {
        // case -99999 -> -1000, 1000 -> 99999

        baseNumber = Number(numberFormat(baseNumber / 1000, 1, '.', ''));

        if (baseNumber === Math.round(baseNumber)) {
            baseNumber = Math.round(baseNumber);
        }

        return `${baseNumber}k`;
    } else if (baseNumber > 999500) {
        // case 999500 -> infinity

        return '999k+';
    } else if (Math.abs(baseNumber) >= 100000) {
        // case 100000 -> 999499

        baseNumber = Math.round(baseNumber / 1000);

        return `${baseNumber}k`;
    }

    return String(Math.round(baseNumber));
};

export const getDeviceType = (): DeviceType => {
    if ('ontouchstart' in window || navigator.maxTouchPoints > 0) {
        if (screen.width <= 700) {
            return 'phone';
        }

        return 'tablet';
    }

    return 'desktop';
};

// Our translation function replaces special characters (like an apostrophe) with HTML character references,
// so we need to replace them again with the original special characters, to display the correct text in React.
export const replaceHtmlEscapeCharacters = (text: string): string => {
    return new DOMParser().parseFromString(text, 'text/html').documentElement.textContent ?? '';
};

import type { ICrop, ISize } from 'ts/common/types';

export enum CoverPhotoImageSize {
    Thumb = 'thumb',
    Medium = 'medium',
    Large = 'large',
    ExtraLarge2 = 'extraLarge2',
    ExtraLarge3 = 'extraLarge3'
}

enum Orientation {
    Landscape = 'landscape',
    Portrait = 'portrait'
}

export function getCanvasFaceDimensions(size: ISize): ISize {
    return {
        height: size.height - size.depth * 2,
        width: size.width - size.depth * 2,
        depth: 0
    };
}

function normalizeBoundsForSvg(size: ISize): ISize {
    if (size.width > size.height) {
        return {
            depth: size.depth,
            height: size.width,
            width: size.height
        };
    }

    return size;
}

export function generateCanvasClipMaskSVG(size: ISize) {
    const normalizedSize = normalizeBoundsForSvg(size);
    const { depth, height, width } = normalizedSize;
    const { height: faceHeight, width: faceWidth } = getCanvasFaceDimensions(normalizedSize);

    const svgString = `
        <svg
            version="1.1"
            viewBox="0 0 ${width} ${height}"
            width="${width}"
            height="${height}"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path fill="#f4f4f5" d="M${depth} ${depth} h${faceWidth} v${faceHeight} h-${faceWidth} v-${faceHeight}" />
        </svg>
    `;

    const base64SVG = window.btoa(svgString);

    return `data:image/svg+xml;base64,${base64SVG}`;
}

export function generateOrnamentClipMaskSVG(boundsName: string) {
    let svgString = '';

    if (boundsName === 'Circle') {
        svgString = `
            <svg version="1.1" viewBox="0 0 105.66 105.73" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" fill="rgb(178, 178, 178)"
                d="m105.64,52.9c.7,26.39-21.02,52.77-52.73,52.83C22.74,105.78-.12,81.23,0,52.57.12,23.73,23.82-.31,53.51,0c30.24.32,52.89,25.81,52.13,52.9Zm-87.1-.03c.03-2.37-1.71-4.17-4.07-4.21-2.21-.04-4.22,1.94-4.21,4.15,0,2.14,1.93,4.09,4.06,4.12,2.36.04,4.19-1.73,4.22-4.06Z"/>
            </svg>
        `;
    } else if (boundsName === 'Haven') {
        svgString = `
            <svg version="1.1" viewBox="0 0 99.06 99.06" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" fill="rgb(178, 178, 178)"
                d="m1.06,81.59c-.17-4.78.88-9.03,2.16-13.26.45-1.49.82-3,1.24-4.49,1.28-4.5.02-8.34-2.96-11.77-2.02-2.32-2.03-2.9.05-5.19,3.64-4.01,4.08-8.55,2.49-13.51-1.15-3.57-2.34-7.13-2.84-10.88-.53-3.97-.6-7.91.71-11.77C3.9,4.88,8.53,1.43,14.65.99c5.78-.42,11.3.66,16.76,2.37,1.43.45,2.89.81,4.34,1.17,4.15,1.05,7.71-.24,10.91-2.85.29-.24.57-.49.86-.73,1.58-1.29,2.55-1.29,4.1.06,2.83,2.48,5.92,4.35,9.88,3.77,2.06-.3,4.1-.77,6.11-1.4,4.78-1.49,9.63-2.46,14.69-2.41,9.92.1,15.66,5.68,15.85,15.68.09,4.99-.91,9.81-2.45,14.53-.72,2.2-1.34,4.44-1.57,6.72-.34,3.41,1.12,6.27,3.23,8.82,2.22,2.68,2.29,2.88-.02,5.52-3.9,4.47-3.78,9.43-1.94,14.65,1.7,4.84,2.82,9.79,2.74,14.95-.03,2.26-.39,4.48-1.1,6.64-2.16,6.62-7.62,9.34-14.29,9.69-5.11.27-10.01-.83-14.84-2.38-2.8-.9-5.59-1.82-8.63-1.59-2.53.2-4.6,1.33-6.54,2.82-.51.39-1,.81-1.49,1.23-1.27,1.1-2.44,1.01-3.7-.1-2.23-1.98-4.64-3.71-7.74-3.96-2.77-.23-5.42.5-8.02,1.32-4.98,1.58-10.02,2.86-15.29,2.64-5.44-.23-10.27-1.82-13.26-6.83-1.85-3.09-2.42-6.51-2.19-9.76Zm7.13-27.03c2.9-.02,5.1-2.22,5.09-5.09-.02-2.85-2.28-5.08-5.14-5.05-2.92.03-5.06,2.2-5.03,5.12.03,3.01,2.09,5.04,5.09,5.03Z"/>
            </svg>
        `;
    } else {
        svgString = `
            <svg version="1.1" viewBox="0 0 83.18 122.43" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" fill="rgb(178, 178, 178)"
                d="m42.02.25c9.34.08,18.67.19,28.01.22,1.64,0,2.43.6,2.72,2.23.89,4.99,3.74,8.42,8.45,10.34,1.88.76,2.18,1.38,1.88,3.35-1.39,9.18-2.26,18.4-2.83,27.67-.44,7.18-.78,14.35-.71,21.54.13,13.74.84,27.44,2.73,41.06.22,1.58-.1,2.69-1.76,3.28-4.73,1.69-7.52,5.11-8.68,9.96-.6,2.52-.7,2.55-3.26,2.53-18.35-.16-36.7-.33-55.05-.49-2.51-.02-2.88-.26-3.35-2.7-.92-4.79-3.76-7.94-8.15-9.84-1.96-.85-2.22-1.38-1.91-3.49,1.46-9.98,2.36-20.01,2.92-30.08.49-8.85.58-17.7.36-26.54-.28-11.2-1.04-22.38-2.48-33.5-.31-2.38-.14-2.71,1.99-3.53,4.39-1.7,7.09-4.86,8.23-9.39C11.86-.01,11.89-.02,14.82,0c9.07.08,18.14.16,27.2.24ZM14.63,60.86c0-2.46-1.63-4.09-4.12-4.11-2.46-.02-4.15,1.65-4.14,4.1,0,2.52,1.72,4.23,4.22,4.22,2.39-.01,4.04-1.74,4.04-4.21Z"/>
            </svg>
        `;
    }

    const base64SVG = window.btoa(svgString);

    return `data:image/svg+xml;base64,${base64SVG}`;
}

export function getCropStringFromCrop(crop: ICrop) {
    return [crop.start.x, crop.start.y, crop.end.x, crop.end.y].join(',');
}

export function getLongestSideForProductSize(size: ISize) {
    const { width, height } = size;
    return height > width ? height : width;
}

export function getMaxCrop(
    photo: { height: number; width: number },
    productSize: Nullable<ISize>,
    isCanvas: boolean,
    disableRotating: boolean
): ICrop {
    const PRECISION_POWER = Math.pow(10, 5);
    const productHeight = productSize?.height ?? 4;
    const productWidth = productSize?.width ?? 4;
    const productSizeOrientation =
        productWidth > productHeight ? Orientation.Landscape : Orientation.Portrait;
    const photoAspectRatio = photo.width / photo.height;
    const photoOrientation =
        photo.width > photo.height ? Orientation.Landscape : Orientation.Portrait;
    const invertBoundsDimensions = productSizeOrientation !== photoOrientation && !disableRotating;
    const productSizeAspectRatio = invertBoundsDimensions
        ? productHeight / productWidth
        : productWidth / productHeight;
    const isBoundsAspectRatioGreaterThanPhoto = productSizeAspectRatio > photoAspectRatio;
    const xPercentage = isBoundsAspectRatioGreaterThanPhoto
        ? 100
        : (productSizeAspectRatio / photoAspectRatio) * 100;
    const yPercentage = isBoundsAspectRatioGreaterThanPhoto
        ? (photoAspectRatio / productSizeAspectRatio) * 100
        : 100;
    const xStart = (100 - xPercentage) / 2;
    const yStart = (100 - yPercentage) / 2;
    const xEnd = xStart + xPercentage;
    const yEnd = yStart + yPercentage;
    const yDistance = yEnd - yStart;
    const xDistance = xEnd - xStart;

    return {
        areProductBoundsFlipped: invertBoundsDimensions,
        bounds: {
            depth: productSize?.depth ?? (isCanvas ? 0.8 : 0),
            height: invertBoundsDimensions ? productWidth : productHeight,
            width: invertBoundsDimensions ? productHeight : productWidth,
            useCanvasCrop: isCanvas
        },
        end: {
            x: Math.floor((xStart + xDistance) * PRECISION_POWER) / PRECISION_POWER,
            y: Math.floor((yStart + yDistance) * PRECISION_POWER) / PRECISION_POWER
        },
        start: {
            x: Math.floor(xStart * PRECISION_POWER) / PRECISION_POWER,
            y: Math.floor(yStart * PRECISION_POWER) / PRECISION_POWER
        }
    };
}

const getSizeThresholdForProductSize = (side: number) => {
    const thresholds = [4, 9, 16, 30, 45, 60, 80];

    const sizeThreshold = thresholds.find((threshold) => side <= threshold);

    return sizeThreshold || 120;
};

export const getScaleInBackground = (productSize: ISize, isCanvas?: boolean) => {
    const longestSide = getLongestSideForProductSize(productSize);

    if (isCanvas) {
        const longestFaceSide = getLongestSideForProductSize(getCanvasFaceDimensions(productSize));
        return longestSide / getSizeThresholdForProductSize(longestFaceSide) - 0.1;
    }

    return longestSide / getSizeThresholdForProductSize(longestSide) - 0.1;
};

export const getSize = (size?: SpApi.IPriceSheetItemSize): Nullable<ISize> => {
    if (!size || size.height === null || size.width === null) {
        return null;
    }

    return {
        depth: size.depth ?? 0,
        height: size.height,
        width: size.width
    };
};

export const getContractStatusTranslation = (
    t: (key: string) => string,
    contractStatus?: string
): string => {
    switch (contractStatus) {
        case 'awaiting-client-signature':
            return t('awaitingClientSignature');
        case 'awaiting-studio-countersign':
            return t('awaitingStudioCountersign');
        case 'completed':
            return t('completed');
        case 'canceled':
            return t('canceled');
        case 'expired':
            return t('expired');
        default:
            return t('awaitingClientSignature');
    }
};

import { useCallback, useEffect, useState } from 'react';
import { FetchMethod, type IFetchHookOptions, type IFetchHookResponse } from 'ts/common/hooks';
import { API } from 'client_react/booking/common';
import { IBookingSession } from 'client_react/booking/types';
import { useClientApiFetch } from 'client_react/bootstrap';

interface BookingSessionError {
    detail?: string;
    status: number;
}

export function isBookingSessionError(
    error: IFetchHookResponse<IBookingSession | BookingSessionError>
): error is BookingSessionError {
    return !!error && error.status >= 400;
}

interface BookingSessionRequiresActionResponse extends BookingSessionError {
    detail: `requires_action:${string}`;
    status: 400;
}

export function isBookingSessionRequiresActionResponse(
    error: IFetchHookResponse<IBookingSession | BookingSessionError>
): error is BookingSessionRequiresActionResponse {
    return (
        isBookingSessionError(error) &&
        error.status === 400 &&
        (error.detail?.startsWith('requires_action:') ?? false)
    );
}

export default function useBookingSession(bookingSessionId: string | null) {
    const [bookingSession, setBookingSession] = useState<IFetchHookResponse<IBookingSession>>(null);
    const [error, setError] = useState<IFetchHookResponse<BookingSessionError>>(null);

    const { performFetch: deferredGetBookingSession, loading: isGetting } = useClientApiFetch<
        IBookingSession | BookingSessionError
    >(`${API.BOOKING_SESSION}/${bookingSessionId}`, {
        defer: true,
        method: FetchMethod.GET
    });

    const { performFetch: postBookingSession, loading: isPosting } = useClientApiFetch<
        IBookingSession | BookingSessionError
    >(API.BOOKING_SESSION, {
        defer: true,
        method: FetchMethod.POST
    });

    const { performFetch: patchBookingSession, loading: isPatching } = useClientApiFetch<
        IBookingSession | BookingSessionError
    >(`${API.BOOKING_SESSION}/${bookingSessionId}`, {
        defer: true,
        method: FetchMethod.PATCH
    });

    const { performFetch: postBookingSessionPayment, loading: isPostingPayment } =
        useClientApiFetch<IBookingSession | BookingSessionError>(
            `${API.BOOKING_SESSION}/${bookingSessionId}/payment`,
            {
                defer: true,
                method: FetchMethod.POST
            }
        );

    const handleApiFetch = useCallback(
        (
            apiMethod: (
                options?: IFetchHookOptions
            ) => Promise<IFetchHookResponse<IBookingSession | BookingSessionError>>
        ) => {
            return async (data?: IFetchHookOptions['data']) => {
                const response = await apiMethod({ data });

                if (isBookingSessionError(response)) {
                    setError(response);
                } else {
                    setError(null);
                    setBookingSession(response);
                }

                return response;
            };
        },
        []
    );

    useEffect(() => {
        // Fetch the booking session if we haven't already
        if (bookingSessionId && !bookingSession) {
            handleApiFetch(deferredGetBookingSession)();
        }
    }, [bookingSession, bookingSessionId, deferredGetBookingSession, handleApiFetch]);

    const getBookingSession = useCallback(
        () => handleApiFetch(deferredGetBookingSession)(),
        [handleApiFetch, deferredGetBookingSession]
    );

    const createBookingSession = useCallback(
        (data: IFetchHookOptions['data']) => handleApiFetch(postBookingSession)(data),
        [handleApiFetch, postBookingSession]
    );

    const updateBookingSession = useCallback(
        (data: IFetchHookOptions['data']) => handleApiFetch(patchBookingSession)(data),
        [handleApiFetch, patchBookingSession]
    );

    const submitPayment = useCallback(
        (data: IFetchHookOptions['data']) => handleApiFetch(postBookingSessionPayment)(data),
        [handleApiFetch, postBookingSessionPayment]
    );

    const heartbeatBookingSession = useCallback(
        () => handleApiFetch(patchBookingSession)({ heartbeat: true }),
        [handleApiFetch, patchBookingSession]
    );

    return {
        bookingSession,
        createBookingSession,
        error,
        getBookingSession,
        heartbeatBookingSession,
        isPending: isGetting || isPosting || isPatching || isPostingPayment,
        submitPayment,
        updateBookingSession
    };
}

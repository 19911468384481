import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Box, Flex, Image } from '@chakra-ui/react';
import { ResizeObserver } from '@juggle/resize-observer';
import type { IBookingSessionImage } from 'client_react/booking/types';

interface Props {
    images: Array<IBookingSessionImage>;
}

const animationPxPerSecond = 50;

const imageSortComparison = (a: IBookingSessionImage, b: IBookingSessionImage) =>
    (a.displayOrder ?? 0) - (b.displayOrder ?? 0);

const BookingMarquee = ({ images }: Props) => {
    const rootRef = useRef<HTMLDivElement>(null);
    const imagesContainerRef = useRef<HTMLDivElement>(null);

    const [rootWidth, setRootWidth] = useState(0);
    const [imagesLoadedCount, setImagesLoadedCount] = useState(0);
    const [imagesOriginalWidth, setImagesOriginalWidth] = useState(0);

    const updateImagesOriginalWidth = useCallback(() => {
        const newImagesOriginalWidth = Array.from(imagesContainerRef.current!.children)
            .slice(0, images.length)
            .reduce((result, child) => result + (child as HTMLElement).offsetWidth, 0);

        setImagesOriginalWidth(newImagesOriginalWidth);
    }, [images]);

    const handleImageLoad = useCallback(() => {
        setImagesLoadedCount((prev) => {
            const newImagesLoadedCount = prev + 1;

            if (newImagesLoadedCount === images.length) {
                updateImagesOriginalWidth();
            }

            return newImagesLoadedCount;
        });
    }, [images, updateImagesOriginalWidth]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(updateImagesOriginalWidth);
        resizeObserver.observe(imagesContainerRef.current!);

        return () => {
            resizeObserver.disconnect();
        };
    }, [updateImagesOriginalWidth]);

    useEffect(() => {
        const resizeObserver = new ResizeObserver(() => {
            setRootWidth(rootRef.current!.offsetWidth);
        });

        resizeObserver.observe(rootRef.current!);

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    const imagesVisibility = imagesLoadedCount === images.length ? 'visible' : 'hidden';
    const imagesRepeat = 2 * Math.ceil(rootWidth / (imagesOriginalWidth || rootWidth || Infinity));
    const animationDuration = (imagesOriginalWidth * imagesRepeat) / animationPxPerSecond;

    return (
        <Box ref={rootRef} height="100%" width="100%">
            <Flex
                ref={imagesContainerRef}
                animation={`${animationDuration}s linear 0s infinite normal none running shift-left-by-half`}
                flexDirection="row"
                justifyContent="flex-start"
                alignItems="stretch"
                height="100%"
                width={`${imagesOriginalWidth * imagesRepeat}px`}
            >
                {/* Render each image once, to measure the width of the element that contains them: */}
                {images.sort(imageSortComparison).map((image, index) => {
                    return (
                        <Image
                            key={index}
                            src={image.url}
                            pointerEvents="none"
                            transform="translate3d(0, 0, 0)"
                            visibility={imagesVisibility}
                            onLoad={handleImageLoad}
                        />
                    );
                })}
                {/* Render each image again repeatedly, to fill in empty space to the right of the original images: */}
                {new Array(Math.max(0, imagesRepeat - 1)).fill(null).flatMap((_, group) =>
                    images.sort(imageSortComparison).map((image, index) => {
                        return (
                            <Image
                                key={`${group}.${index}`}
                                src={image.url}
                                pointerEvents="none"
                                transform="translate3d(0, 0, 0)"
                                visibility={imagesVisibility}
                            />
                        );
                    })
                )}
            </Flex>
        </Box>
    );
};

export default BookingMarquee;

import { useCallback, useEffect, useState } from 'react';
import { FetchMethod, type IFetchHookOptions, type IFetchHookResponse } from 'ts/common/hooks';
import { API } from 'client_react/booking/common';
import { useClientApiFetch } from 'client_react/bootstrap';

interface ContractError {
    detail?: string;
    status: number;
}

export function isContractError(
    error: IFetchHookResponse<SpApi.Client.IContract | ContractError>
): error is ContractError {
    return !!error && error.status >= 400;
}

export default function useContract(
    contractPublicId?: string | null,
    contactEmail?: string | null
) {
    const [contract, setContract] = useState<IFetchHookResponse<SpApi.Client.IContract>>(null);

    const { performFetch: deferredGetContract, loading: isGettingContract } = useClientApiFetch<
        SpApi.Client.IContract | ContractError
    >(`${API.CONTRACT}/${contractPublicId}?email=${contactEmail}`, {
        defer: true,
        method: FetchMethod.GET
    });

    const { performFetch: postContract, loading: isPosting } = useClientApiFetch<
        SpApi.Client.IContract | ContractError
    >(`${API.CONTRACT}`, {
        defer: true,
        method: FetchMethod.POST
    });

    const { performFetch: patchContract, loading: isPatching } = useClientApiFetch<
        SpApi.Client.IContract | ContractError
    >(`${API.CONTRACT}/${contract?.publicId}`, {
        defer: true,
        method: FetchMethod.PATCH
    });

    const { performFetch: putContractSignature, loading: isPuttingContractSignature } =
        useClientApiFetch<SpApi.Client.IContract | ContractError>(
            `${API.CONTRACT}/${contract?.publicId}/signature`,
            {
                defer: true,
                method: FetchMethod.PUT
            }
        );

    const handleApiFetch = useCallback(
        (
            apiMethod: (
                options?: IFetchHookOptions
            ) => Promise<IFetchHookResponse<SpApi.Client.IContract | ContractError>>
        ) => {
            return async (options?: IFetchHookOptions) => {
                const response = await apiMethod(options);

                if (!isContractError(response)) {
                    setContract(response);
                }

                return response;
            };
        },
        []
    );

    useEffect(() => {
        // Fetch the contract if we don't have it
        if (contractPublicId && contactEmail && !contract) {
            handleApiFetch(deferredGetContract)();
        }
    }, [contactEmail, contractPublicId, contract, deferredGetContract, handleApiFetch]);

    const createContract = useCallback(
        (options: IFetchHookOptions) => handleApiFetch(postContract)(options),
        [handleApiFetch, postContract]
    );

    const signContract = useCallback(
        (options: IFetchHookOptions) => handleApiFetch(putContractSignature)(options),
        [handleApiFetch, putContractSignature]
    );

    const updateContract = useCallback(
        (options: IFetchHookOptions) => handleApiFetch(patchContract)(options),
        [handleApiFetch, patchContract]
    );

    return {
        contract,
        createContract,
        isPending: isGettingContract || isPosting || isPatching || isPuttingContractSignature,
        signContract,
        updateContract
    };
}

import React, { useMemo } from 'react';
import { Flex, Link, Text } from '@chakra-ui/react';
import { Link as LinkIcon, Phone } from 'react-feather';
import { useTranslate } from 'ts/common';
import { GoogleMeetIcon, LocationIcon } from 'ts/common/components/icons';
import { IBookingSession, IBookingSessionType, LocationType } from 'client_react/booking/types';

type Props = {
    bookingSession: Nullable<IBookingSession>;
    bookingSessionType: Nullable<IBookingSessionType>;
};

const LocationSummary = ({ bookingSession, bookingSessionType }: Props) => {
    const t = useTranslate('booking');

    const { locationType, locationIcon, locationValue } = useMemo(() => {
        // priority for determining location type is as follows:
        // bookingSession.locationType > derived from bookingSession values > bookingSessionType.locationType
        let locationType: LocationType | undefined = bookingSession?.locationType;

        if (!locationType && bookingSession) {
            if (bookingSession.address) {
                locationType = 'address';
            } else if (bookingSession.phoneNumber) {
                locationType = 'phone';
            } else if (bookingSession.url) {
                locationType = 'url';
            }
        }

        locationType ??= bookingSessionType?.locationType;

        switch (locationType) {
            case 'address':
                return {
                    locationType,
                    locationIcon: <LocationIcon style={{ flex: '0 0 auto' }} />,
                    locationValue: bookingSession?.address ?? bookingSessionType?.address
                };
            case 'phone':
                return {
                    locationType,
                    locationIcon: <Phone viewBox="-2 -2 28 28" style={{ flex: '0 0 auto' }} />,
                    locationValue: bookingSession?.phoneNumber ?? bookingSessionType?.phoneNumber
                };
            case 'url':
                return {
                    locationType,
                    locationIcon: <LinkIcon viewBox="-2 -2 28 28" style={{ flex: '0 0 auto' }} />,
                    locationValue: bookingSession?.url ?? bookingSessionType?.url
                };
            case 'google-meet':
                return {
                    locationType,
                    locationIcon: (
                        <GoogleMeetIcon width={24} height={24} style={{ flex: '0 0 auto' }} />
                    ),
                    locationValue: bookingSession?.googleMeetUri
                };
            default:
                return {};
        }
    }, [
        bookingSession,
        bookingSessionType?.address,
        bookingSessionType?.locationType,
        bookingSessionType?.phoneNumber,
        bookingSessionType?.url
    ]);

    if (!locationType) {
        return null;
    }

    return (
        <Flex
            alignItems="center"
            gap="8px"
            whiteSpace={{ base: 'normal', lg: 'nowrap' }}
            overflowX="hidden"
            fontFamily="open-sans"
        >
            {locationIcon}
            {locationType === 'google-meet' ? (
                locationValue ? (
                    <Link href={locationValue} color="brand.primary" target="_blank">
                        {t('googleMeet')}
                    </Link>
                ) : (
                    <Text color="darkText">{t('googleMeet')}</Text>
                )
            ) : (
                <Text
                    color="darkText"
                    title={locationValue}
                    overflowX="hidden"
                    textOverflow="ellipsis"
                >
                    {locationValue}
                </Text>
            )}
        </Flex>
    );
};

export default LocationSummary;

import React, { useEffect } from 'react';
import {
    Box,
    Button,
    Flex,
    Icon,
    IconButton,
    Image,
    Link,
    ListItem,
    Text,
    UnorderedList,
    useBreakpointValue,
    useTheme
} from '@chakra-ui/react';
import { css } from '@emotion/react';
import { getContractStatusTranslation } from 'ts/common/common';
import { BookingContractDownloadIcon, PrinterIcon } from 'ts/common/components/icons';
import { useFillableFields, useTranslate } from 'ts/common/hooks';
import { usePrintOrDownloadContract } from 'ts/common/hooks/contract/usePrintOrDownloadContract';
import ModernButton from 'ts/studio/common/ModernButton';
import ContractActionTag from './ContractActionTag';
import ContractSignature from './ContractSignature';

const fillableFieldStyle = (theme) => css`
    p {
        margin: 1em 0;
    }

    ol,
    ul {
        margin: 1em;
        padding: revert;
    }

    .fillable-field-short {
        background: inherit;
        border: none;
        border-bottom: 1px dashed ${theme.colors.gray[400]};
        color: ${theme.colors.text};
        height: 30px;
        min-width: 100px;
        outline: none;

        &:focus {
            background-color: ${theme.colors.gray[200]};
            color: black;
        }

        /* Remove Chrome's autocomplete styles */
        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
            -webkit-box-shadow: 0 0 0 30px white inset !important;
        }

        &::placeholder {
            color: ${theme.isLightColorScheme ? theme.colors.gray[400] : theme.colors.gray[200]};
            font-style: italic;
            opacity: 0.7;
        }
    }

    .fillable-field-long {
        background: inherit;
        border-color: ${theme.colors.gray[300]};
        color: ${theme.colors.text};
        min-height: 140px;
        width: 100%;

        &:focus {
            background-color: ${theme.colors.gray[200]};
            color: black;
        }

        &::placeholder {
            color: ${theme.isLightColorScheme ? theme.colors.gray[400] : theme.colors.gray[200]};
            font-style: italic;
            opacity: 0.7;
        }
    }

    .text-center {
        text-align: center;
    }
`;

interface ContractProps {
    brandName: string;
    contractData: SpApi.IBaseContract;
    showDownloadButton?: boolean;
    heartbeat?: () => void;
    isClient?: boolean;
    isClientThemed?: boolean;
    isConfirmation?: boolean;
    openSignatureModal: () => void;
    onFillableFieldsChange?: (fields: SpApi.IContractFillableField[]) => void;
    translateKeyBase?: string;
    onStart?: () => void;
}

const Contract: React.FC<ContractProps> = ({
    brandName,
    contractData,
    showDownloadButton = false,
    heartbeat,
    isClient = true,
    isClientThemed = true,
    isConfirmation = false,
    openSignatureModal,
    onFillableFieldsChange,
    translateKeyBase = 'contract',
    onStart
}) => {
    const [signButtonElement, setSignButtonElement] = React.useState<HTMLElement | null>(null);

    const theme = useTheme();
    const t = useTranslate(translateKeyBase);
    const isMobile = useBreakpointValue({ base: true, md: false });
    const { fillableFields, fillableFieldRequiringAttention, parsedContractHtml } =
        useFillableFields({
            areFillableFieldsCompleted: false,
            contractHtml: contractData.bodyHtml,
            isClient,
            shootDate: contractData.shootDate
        });

    const contractUrl =
        contractData.links?.alternate?.href +
        (isConfirmation ? '&timezone=' + Intl.DateTimeFormat().resolvedOptions().timeZone : '');
    const { downloadContract, printContract } = usePrintOrDownloadContract(contractUrl);

    const getCTAButton = (
        fillableFieldsCompletedCount: number,
        fillableFieldsTotalCount: number,
        nextEmptyField?: SpApi.IContractFillableField
    ) => {
        const ButtonComponent = isClientThemed ? Button : ModernButton;

        if (contractData.contractStatus === 'completed') {
            return (
                <ButtonComponent onClick={printContract} paddingX="32px" variant="primary">
                    {t('print')}
                </ButtonComponent>
            );
        }

        if (fillableFieldsTotalCount > 0 && fillableFieldsCompletedCount === 0) {
            return (
                <ButtonComponent
                    onClick={() => {
                        heartbeat?.();
                        onStart?.();

                        if (nextEmptyField) {
                            const fieldElement = document.getElementById(nextEmptyField.id);

                            if (fieldElement) {
                                fieldElement.scrollIntoView({
                                    behavior: 'smooth',
                                    block: 'center'
                                });
                                fieldElement.focus();
                            }
                        }
                    }}
                    paddingX="32px"
                    variant="primary"
                >
                    {t('start')}
                </ButtonComponent>
            );
        }

        return (
            <ButtonComponent
                onClick={() => {
                    heartbeat?.();
                    const signButtonElement = document.getElementById('signButton');

                    if (signButtonElement) {
                        signButtonElement.scrollIntoView({ behavior: 'smooth', block: 'center' });
                        signButtonElement.focus();
                    }
                }}
                paddingX="32px"
                isDisabled={fillableFieldsCompletedCount < fillableFieldsTotalCount}
                variant="primary"
            >
                {t('sign')}
            </ButtonComponent>
        );
    };

    useEffect(() => {
        if (onFillableFieldsChange) {
            onFillableFieldsChange(fillableFields);
        }
    }, [fillableFields, onFillableFieldsChange]);

    useEffect(() => {
        const handleFocus = (event: FocusEvent) => {
            const target = event.target as HTMLElement;

            if (
                target.classList.contains('fillable-field-short') ||
                target.classList.contains('fillable-field-long')
            ) {
                heartbeat?.();
            }
        };

        document.addEventListener('focus', handleFocus, true);

        return () => {
            document.removeEventListener('focus', handleFocus, true);
        };
    }, [heartbeat]);

    useEffect(() => {
        const element = document.getElementById('signButton');

        if (element) {
            setSignButtonElement(element);
        }
    }, []);

    const fillableFieldsCompletedCount = fillableFields.filter(
        (field) => field.value !== ''
    ).length;
    const showSignHereActionTag =
        (fillableFieldsCompletedCount >= fillableFields.length ||
            contractData.contractStatus === 'awaiting-studio-countersign') &&
        contractData.contractStatus !== 'completed' &&
        signButtonElement !== null;

    return (
        <Box maxW="1000px" mx="auto">
            <Flex justifyContent="space-between" alignItems="flex-start" mb={1}>
                <Text fontSize="20px" fontWeight="600" color="neutral.600">
                    {contractData.name}
                </Text>
                <Flex gap={4} alignItems="center">
                    {showDownloadButton && (
                        <>
                            <IconButton
                                icon={<Icon as={PrinterIcon} boxSize="24px" />}
                                variant="ghost"
                                size="24px"
                                aria-label="Print contract"
                                onClick={printContract}
                                cursor="pointer"
                            />
                            <Link
                                onClick={() => {
                                    heartbeat?.();
                                    downloadContract();
                                }}
                                download
                                _hover={{ textDecoration: 'none' }}
                            >
                                <IconButton
                                    icon={<Icon as={BookingContractDownloadIcon} boxSize="24px" />}
                                    variant="ghost"
                                    size="24px"
                                    aria-label="Download contract"
                                    cursor="pointer"
                                />
                            </Link>
                        </>
                    )}
                </Flex>
            </Flex>
            <Box
                bg="transparent"
                px={3}
                py={1}
                border="1px solid"
                borderColor="neutral.400"
                borderRadius="full"
                display="inline-block"
                fontSize="12px"
                fontFamily="open-sans"
                color="neutral.400"
                mb={3}
            >
                {getContractStatusTranslation(t, contractData.contractStatus)}
            </Box>

            {/* Contract Info Section */}
            <Flex mb={10} justifyContent="space-between" data-testid="contract-info-section">
                <Box flex={1}>
                    <Text color="neutral.600" fontSize="14px" fontWeight="600" mb={1}>
                        {t('from')}
                    </Text>
                    <Text color="neutral.500" fontSize="14px" fontWeight="400">
                        {brandName}
                    </Text>
                </Box>
                <Box flex={1}>
                    <Text color="neutral.600" fontSize="14px" fontWeight="600" mb={1}>
                        {t('primaryContact')}
                    </Text>
                    <Flex alignItems="center" gap={2}>
                        <Text fontSize="14px" fontWeight="400" color="neutral.500">
                            {contractData.contacts[0]?.name}
                        </Text>
                    </Flex>
                </Box>
                <Box flex={1}>
                    {fillableFields.length > 0 && (
                        <>
                            <Text color="neutral.600" fontSize="14px" fontWeight="600" mb={1}>
                                {t('requiredFields')}
                            </Text>
                            <Text fontSize="14px" fontWeight="400" color="neutral.500">
                                {fillableFieldsCompletedCount} / {fillableFields.length}
                            </Text>
                        </>
                    )}
                </Box>
                {!isConfirmation &&
                    getCTAButton(
                        fillableFieldsCompletedCount,
                        fillableFields.length,
                        fillableFieldRequiringAttention
                    )}
            </Flex>

            {/* Brand Logo Section */}
            {contractData.brandTheme?.emailBrandLogo?.displayUrl?.original && (
                <Box mb="45px" display="flex" justifyContent="center">
                    <Image
                        src={contractData.brandTheme.emailBrandLogo.displayUrl.original}
                        alt={contractData.brandTheme.logoAltText || brandName}
                        maxW="400px"
                        objectFit="contain"
                        loading="lazy"
                    />
                </Box>
            )}

            {/* Contract Content */}
            <Box mb={12}>
                <Box
                    dangerouslySetInnerHTML={{
                        __html: parsedContractHtml
                    }}
                    fontSize="16px"
                    fontWeight="400"
                    fontFamily="open-sans"
                    lineHeight="1.6"
                    color="neutral.500"
                    css={() => {
                        return fillableFieldStyle(theme);
                    }}
                />
            </Box>

            {/* Signatures Section */}
            <Box mt={12}>
                <Text fontSize="18px" fontWeight="700" color="neutral.500" mb={4}>
                    {t('signatures')}
                </Text>
                <ContractSignature
                    isSignable={isClient}
                    name={contractData.contacts[0].name}
                    openSignatureModal={() => {
                        heartbeat?.();
                        openSignatureModal();
                    }}
                    signature={contractData?.signatureClients?.[0]?.signature}
                    translateKeyBase={translateKeyBase}
                    filledAllRequiredFields={fillableFieldsCompletedCount >= fillableFields.length}
                />
                {/* TODO: Second Signer goes here between the two signatures */}
                <ContractSignature
                    name={brandName}
                    isSignable={!isClient}
                    openSignatureModal={openSignatureModal}
                    signature={contractData?.signatureStudio?.signature}
                    translateKeyBase={translateKeyBase}
                    filledAllRequiredFields={fillableFieldsCompletedCount >= fillableFields.length}
                />
            </Box>

            {/* Signature Information Section */}
            {contractData.signatureClients?.[0] && (
                <Box
                    mt={8}
                    bg={
                        theme.isLightColorScheme === false
                            ? theme.colors.gray[700]
                            : theme.colors.gray[200]
                    }
                    p={4}
                    borderTop="1px solid"
                    borderColor="neutral.300"
                    fontFamily="open-sans"
                >
                    <Text fontSize="12px" fontWeight="600" mb={2}>
                        {t('clientSignatureInformation')}
                    </Text>
                    <UnorderedList margin={0} spacing={1} padding="revert">
                        <ListItem fontSize="12px" color="gray.400" fontWeight={400}>
                            {t('contractId')}: {contractData.publicId}
                        </ListItem>
                        <ListItem fontSize="12px" color="gray.400" fontWeight={400}>
                            {t('signatureId')}:{' '}
                            {contractData.signatureClients[0].signature.publicId}
                        </ListItem>
                        <ListItem fontSize="12px" color="gray.400" fontWeight={400}>
                            {t('timestamp')}: {contractData.signatureClients[0].created}
                        </ListItem>
                        <ListItem fontSize="12px" color="gray.400" fontWeight={400}>
                            {t('ipAddress')}: {contractData.signatureClients[0].ipAddress}
                        </ListItem>
                        <ListItem fontSize="12px" color="gray.400" fontWeight={400}>
                            {t('userAgent')}: {contractData.signatureClients[0].userAgent}
                        </ListItem>
                    </UnorderedList>
                </Box>
            )}

            <Box position="absolute" right={isMobile ? '0px' : '-75px'} top="0" height="100%">
                {fillableFieldRequiringAttention && (
                    <ContractActionTag
                        actionText={t('fillHere')}
                        element={document.getElementById(fillableFieldRequiringAttention.id)}
                    />
                )}
                {showSignHereActionTag && (
                    <ContractActionTag actionText={t('signHere')} element={signButtonElement} />
                )}
            </Box>
        </Box>
    );
};

export default Contract;

import React from 'react';
import { Box, Flex, Link, Text } from '@chakra-ui/react';
import { getContractStatusTranslation, useTranslate } from 'ts/common';
import { CalendarIcon, ClockIcon, ContractIcon, CurrencyIcon } from 'ts/common/components/icons';
import { translateTimeUnits, useTranslatedTimeUnits } from 'ts/common/hooks/useTranslatedTimeUnits';
import LocationSummary from 'client_react/booking/components/LocationSummary';
import { getTimeZoneAbbreviation, getTimeZoneName } from 'client_react/booking/helpers';
import { IBookingSession, IBookingSessionType } from 'client_react/booking/types';

const BookingSessionSummary = ({
    bookingSession,
    bookingSessionType,
    isConfirmation,
    contract,
    onViewContract
}: {
    bookingSession: Nullable<IBookingSession>;
    bookingSessionType: Nullable<IBookingSessionType>;
    isConfirmation?: boolean;
    contract?: SpApi.Client.IContract | null;
    onViewContract?: () => void;
}) => {
    const t = useTranslate('booking');
    const contractTranslate = useTranslate('contract');
    const baseTranslate = useTranslate();

    const durationUnitString = useTranslatedTimeUnits(
        bookingSessionType?.durationUnit ?? null,
        bookingSessionType?.duration
    );

    const endDateTime = bookingSession?.endTime ? new Date(bookingSession.endTime) : null;
    const startDateTime = bookingSession?.startTime ? new Date(bookingSession.startTime) : null;

    // The duration of the booking session is calculated based on the start and end time
    const bookingSessionDuration =
        endDateTime && startDateTime
            ? Math.floor((endDateTime.getTime() - startDateTime.getTime()) / 1000 / 60)
            : null;
    const bookingSessionDurationString = bookingSessionDuration
        ? bookingSessionDuration % 60 === 0
            ? `${bookingSessionDuration / 60} ${translateTimeUnits(
                  baseTranslate,
                  'hour',
                  bookingSessionDuration / 60
              )}`
            : `${bookingSessionDuration} ${translateTimeUnits(
                  baseTranslate,
                  'minute',
                  bookingSessionDuration
              )}`
        : null;
    const bookingSessionTypeDurationString = bookingSessionType?.duration
        ? `${bookingSessionType.duration} ${durationUnitString}`
        : null;
    const durationString = bookingSessionDurationString ?? bookingSessionTypeDurationString;

    const timeZoneAbbreviation = getTimeZoneAbbreviation();
    const timeZoneString = isConfirmation
        ? `(${timeZoneAbbreviation ? timeZoneAbbreviation + ' - ' : ''}${getTimeZoneName()})`
        : timeZoneAbbreviation;

    return (
        <Flex
            flexDirection={{ base: 'column', lg: isConfirmation ? 'column' : 'row' }}
            alignItems={{ base: 'stretch', lg: isConfirmation ? 'flex-start' : 'center' }}
            gap={{ base: '12px', lg: isConfirmation ? '16px' : '32px' }}
            fontWeight={600}
            color="darkText"
            whiteSpace={{ base: 'normal', lg: 'nowrap' }}
        >
            {!isConfirmation && (bookingSessionType?.hasPrice || bookingSession?.hasPrice) && (
                <Flex alignItems="center" gap="8px">
                    <CurrencyIcon style={{ flex: '0 0 auto' }} />
                    <Text color="darkText">{(bookingSession ?? bookingSessionType)?.price}</Text>
                </Flex>
            )}
            {durationString && (
                <Flex alignItems="center" gap="8px">
                    <ClockIcon style={{ flex: '0 0 auto' }} />
                    <Text color="darkText">{durationString}</Text>
                </Flex>
            )}
            <LocationSummary
                bookingSession={bookingSession}
                bookingSessionType={bookingSessionType}
            />
            {bookingSession?.startTime && (
                <Flex alignItems="center" gap="8px">
                    <CalendarIcon style={{ flex: '0 0 auto' }} />
                    <Text color="darkText">
                        {bookingSession.startTime &&
                            new Date(bookingSession.startTime).toLocaleDateString([], {
                                dateStyle: 'full'
                            })}
                        {' - '}
                        {new Date(bookingSession.startTime).toLocaleTimeString([], {
                            timeStyle: 'short'
                        })}{' '}
                        {timeZoneString}
                    </Text>
                </Flex>
            )}
            {isConfirmation && bookingSession && bookingSession.hasPrice && (
                <>
                    <Flex alignItems="center" gap="8px">
                        <CurrencyIcon style={{ flex: '0 0 auto' }} />
                        <Text color="darkText">
                            {t('confirmationPage.paid')}
                            {': '}
                            {bookingSession.pendingAmountPaid}{' '}
                            {bookingSession.amountPaid !== bookingSession.pendingAmountPaid &&
                                t('confirmationPage.pendingConfirmation')}
                            {bookingSession.isConfirmed && bookingSession.invoiceUrl && (
                                <>
                                    {' - '}
                                    <Link
                                        href={bookingSession.invoiceUrl}
                                        color="brand.primary"
                                        target="_blank"
                                    >
                                        {t('confirmationPage.viewInvoice')}
                                    </Link>
                                </>
                            )}
                        </Text>
                    </Flex>
                    {!bookingSession.isPaidInFull && (
                        <Flex alignItems="center" gap="8px">
                            <CurrencyIcon style={{ flex: '0 0 auto' }} />
                            <Text color="darkText">
                                {t('confirmationPage.remainingBalance')}
                                {': '}
                                {bookingSession.pendingAmountDue}{' '}
                                {bookingSession.amountDue !== bookingSession.pendingAmountDue &&
                                    t('confirmationPage.pendingConfirmation')}
                                {bookingSession.isConfirmed && bookingSession.invoiceUrl && (
                                    <>
                                        {' - '}
                                        <Link
                                            href={bookingSession.invoiceUrl}
                                            color="brand.primary"
                                            target="_blank"
                                        >
                                            {t('confirmationPage.makePayment')}
                                        </Link>
                                    </>
                                )}
                            </Text>
                        </Flex>
                    )}
                </>
            )}
            {contract && onViewContract && (
                <Flex alignItems="center" gap="8px">
                    <ContractIcon height="20px" width="20px" style={{ marginLeft: '2px' }} />
                    <Text color="darkText">
                        {contract.name}
                        {' - '}
                    </Text>
                    <Link
                        color="brand.primary"
                        target="_blank"
                        display="inline-flex"
                        alignItems="center"
                        fontFamily="open-sans"
                        _hover={{ textDecoration: 'none' }}
                        onClick={onViewContract}
                    >
                        {t('confirmationPage.viewContract')}
                    </Link>
                    <Box
                        bg="transparent"
                        px={3}
                        py={1}
                        border="1px solid"
                        borderColor="neutral.400"
                        borderRadius="full"
                        display="inline-block"
                        fontSize="12px"
                        fontFamily="open-sans"
                        color="neutral.400"
                    >
                        {getContractStatusTranslation(contractTranslate, contract.contractStatus)}
                    </Box>
                </Flex>
            )}
        </Flex>
    );
};

export default BookingSessionSummary;

export const ROUTE = {
    DATE_AND_TIME: 'date-and-time',
    CONTACT_INFORMATION: 'contact-information',
    CONTRACT: 'contract',
    CONTRACT_CONFIRMATION: 'contract-confirmation',
    PAYMENT: 'payment',
    CONFIRMATION: 'confirmation'
};

export const API = {
    BOOKING_SESSION_TYPE: 'booking-session-type',
    BOOKING_SESSION: 'booking-session',
    CONTRACT: 'contract'
};

export const PARAM = {
    SESSION_TYPE_SLUG: 'sessionTypeSlug',
    BOOKING_SESSION_ID: 'bookingSessionId'
};

export const QUERY = {
    BOOKING_SESSION_ID: 'bookingSessionId'
};

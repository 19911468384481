import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { Box, Link, styled } from 'sp-ui';
import BRAND_ROUTES from 'Sp/Angular/Routes/Client/Brand';
import Route from 'Sp/Route';
import { useTranslate } from 'ts/common/hooks';
import { IBrandHomepage } from '../types';

interface INavProps {
    brandData: IBrandHomepage;
}

interface NavItem {
    name: string;
    route: string;
    skipTranslation?: boolean;
}

const NavContainer = styled(Box)(
    ({ theme }) => `
        width: 100%;
        ${theme.responsive.mediumScreenDown`
            width: calc(100% - 32px);
        `}
        max-width: 1200px;
        text-align: center;
        border-bottom: 1px solid ${theme.brand.colors.theme.secondary.medium};
        padding: 10px 0;
        margin: 0 auto 24px auto;
    `
);
const NavLink = styled(Link)(
    ({ theme }) => `
        color: ${theme.brand.colors.user.primary};
        text-decoration: none;
        text-underline-offset: 12px;
        text-transform: uppercase;
        margin: 0 20px;
        font-family: ${theme.brand.fonts.primary};
        letter-spacing: 0.07em;
        margin: 0 20px;

        &:active, &:hover, &:link, &:visited {
            color: ${theme.brand.colors.user.primary};
        }

        &:hover, &:focus, &.selected {
            text-decoration: underline;
            text-decoration-thickness: 2px;
            font-weight: 700;
            box-shadow: none;
        }

        &:nth-of-type(1) {
            margin: 0 20px 0 0;
        }

        &:last-child {
            margin: 0 0 0 20px;
        }
    `
);

const Nav: React.FC<INavProps> = ({ brandData }) => {
    const t = useTranslate();
    const params = useParams();
    const location = useLocation();
    const categoryRoute = Route.get(BRAND_ROUTES.CATEGORY, { categoryId: params.categoryId });
    const aboutRoute = Route.get(BRAND_ROUTES.ABOUT);
    const bookingRoute = Route.get(BRAND_ROUTES.BOOKING);
    const contactRoute = Route.get(BRAND_ROUTES.CONTACT);
    const homeRoute = Route.get(BRAND_ROUTES.HOME);
    const navigate = useNavigate();

    const selected = (routeToMatch: string) => {
        return location.pathname === routeToMatch;
    };

    const getBookingNavName = () => {
        if (brandData.bookingNavLinkText) {
            return {
                name: brandData.bookingNavLinkText,
                skipTranslation: true
            };
        }

        return {
            name: 'booking.brandHomepage.navLinkText',
            skipTranslation: false
        };
    };

    const navItems: NavItem[] = [
        {
            name: 'home',
            route: homeRoute
        }
    ];

    if (brandData.enableAboutPage) {
        navItems.push({
            name: 'about',
            route: aboutRoute
        });
    }

    if (brandData.enableContactForm) {
        navItems.push({
            name: 'contact',
            route: contactRoute
        });
    }

    if (brandData.showBookingPage) {
        const bookingNav = getBookingNavName();
        navItems.push({
            ...bookingNav,
            route: bookingRoute
        });
    }

    return (
        <NavContainer data-testid="nav-container">
            {navItems.map(({ route, name, skipTranslation = false }) => (
                <NavLink
                    key={name}
                    data-testid={`${name}-link`}
                    href={route}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate(route);
                    }}
                    className={
                        selected(route) || (selected(categoryRoute) && route === homeRoute)
                            ? 'selected'
                            : ''
                    }
                >
                    {skipTranslation ? name : t(name)}
                </NavLink>
            ))}
        </NavContainer>
    );
};

export default Nav;

import { useCallback, useEffect, useMemo, useState } from 'react';

type FieldType = 'short' | 'long';

const COMPLETED_FILLABLE_FIELD_TOKEN =
    /<(tokenshort|tokenlong) class="redactor-component" data-redactor-type="(fillableShortField|fillableLongField)" data-populated="true">((\s|\S)*?)<\/(tokenshort|tokenlong)>/gi;
const COMPLETED_TRANSFORMED_FILLABLE_FIELD_TOKEN =
    /<(textarea|input|label) id="(.*?)" class="(fillable-field-long|fillable-field-short)" size="(.*?)" value="(.*?)"><\/(textarea|input|label)>/gi;
const FILLABLE_FIELD_TOKEN =
    /<(tokenshort|tokenlong) class="redactor-component" data-redactor-type="(fillableShortField|fillableLongField)">(.*?)<\/(tokenshort|tokenlong)>/gi;
const TRANSFORMED_FILLABLE_FIELD_TOKEN =
    /<(textarea|input) id="(.*?)" class="(fillable-field-long|fillable-field-short)" size="(.*?)" placeholder="(.*?)" (.*?)><\/(textarea|input)>/gi;
const PLACEHOLDER_TOKEN = /placeholder="(.*?)"/;
const SHOOT_DATE_TOKEN = /<token data-token="\(\(contract\.shoot_date\)\)">(.*?)<\/token>/gi;

interface IUseFillableFields {
    areFillableFieldsCompleted: boolean;
    contractHtml: string;
    isClient?: boolean;
    shootDate?: Nullable<string>;
}

const formatDateToLocalTimezone = (utcDateString: string): string => {
    const date = new Date(utcDateString);
    return date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric'
    });
};

export const useFillableFields = ({
    areFillableFieldsCompleted,
    contractHtml,
    isClient = false,
    shootDate
}: IUseFillableFields) => {
    const getFillableFieldReplacedToken = (
        fieldType: FieldType,
        matchedText: string,
        id: number,
        isFieldCompleted: boolean
    ) => {
        const fillableFieldName = matchedText;
        const inputType = fieldType === 'short' ? 'input' : isFieldCompleted ? 'label' : 'textarea';
        const inputLength =
            fieldType === 'short' ? `size="${fillableFieldName.length + 2}"` : 'size="auto"';
        const fieldText = isFieldCompleted && fieldType === 'long' ? fillableFieldName : '';

        return `<${inputType} id="${id}-fib" class="fillable-field-${fieldType}" ${inputLength} ${
            isFieldCompleted
                ? `value="${fillableFieldName}"`
                : `placeholder="${fillableFieldName}" autocomplete="off"`
        }>${fieldText}</${inputType}>`;
    };

    const replaceShootDateToken = useCallback(
        (bodyHtml: string) => {
            if (!isClient || !shootDate) {
                return bodyHtml;
            }

            return bodyHtml.replace(SHOOT_DATE_TOKEN, () => {
                const localDate = formatDateToLocalTimezone(shootDate);
                return `<token data-token="((contract.shoot_date))">${localDate}</token>`;
            });
        },
        [isClient, shootDate]
    );

    const replaceFillableFields = useCallback(
        (bodyHtml: string, areFillableFieldsCompleted: boolean = false) => {
            let index = 0;
            const FILLABLE_FIELD_PATTERN = areFillableFieldsCompleted
                ? COMPLETED_FILLABLE_FIELD_TOKEN
                : FILLABLE_FIELD_TOKEN;
            return bodyHtml.replace(FILLABLE_FIELD_PATTERN, (_, __, fieldType, matchedText) => {
                return getFillableFieldReplacedToken(
                    fieldType === 'fillableShortField' ? 'short' : 'long',
                    matchedText,
                    index++,
                    areFillableFieldsCompleted
                );
            });
        },
        []
    );

    const getInitialFillableFieldData = (
        bodyHtml: string,
        isCompletedFillableField: boolean = false
    ) => {
        const fillableFieldTokens = bodyHtml.match(
            isCompletedFillableField
                ? COMPLETED_TRANSFORMED_FILLABLE_FIELD_TOKEN
                : TRANSFORMED_FILLABLE_FIELD_TOKEN
        );
        return fillableFieldTokens && fillableFieldTokens.length
            ? fillableFieldTokens.map((token, index) => {
                  const placeholder = PLACEHOLDER_TOKEN.exec(token);

                  return {
                      id: `${index}-fib`,
                      index,
                      value: '',
                      descriptionText: placeholder ? placeholder[1] : ''
                  };
              })
            : [];
    };

    const parsedContractHtml = useMemo(() => {
        const htmlWithShootDate = replaceShootDateToken(contractHtml);
        return replaceFillableFields(htmlWithShootDate, areFillableFieldsCompleted);
    }, [areFillableFieldsCompleted, contractHtml, replaceFillableFields, replaceShootDateToken]);

    const [fillableFields, setFillableFields] = useState<SpApi.IContractFillableField[]>(
        getInitialFillableFieldData(parsedContractHtml, areFillableFieldsCompleted)
    );

    const [fillableFieldRequiringAttention, setFillableFieldRequiringAttention] = useState<
        SpApi.IContractFillableField | undefined
    >(fillableFields.find((field) => field.value === ''));

    useEffect(() => {
        const updateFillableFieldDataFromEvent = (event: Event) => {
            const target = event.target as HTMLInputElement | HTMLTextAreaElement;
            setFillableFields((prevData) =>
                prevData.map((field) =>
                    field.id === target.id ? { ...field, value: target.value } : field
                )
            );
        };

        const updateFillableFieldRequiringAttention = (event: Event) => {
            const target = event.target as HTMLInputElement | HTMLTextAreaElement;

            if (fillableFieldRequiringAttention?.id === target.id) {
                setFillableFieldRequiringAttention(
                    fillableFields.find((field) => field.value === '')
                );
            }
        };

        fillableFields.forEach((fillableField) => {
            const input = document.getElementById(fillableField.id);

            if (input) {
                input.addEventListener('input', updateFillableFieldDataFromEvent);
                input.addEventListener('blur', updateFillableFieldRequiringAttention);
            }
        });

        return () => {
            fillableFields.forEach((fillableField) => {
                const input = document.getElementById(fillableField.id);

                if (input) {
                    input.removeEventListener('input', updateFillableFieldDataFromEvent);
                    input.removeEventListener('blur', updateFillableFieldRequiringAttention);
                }
            });
        };
    }, [fillableFields, fillableFieldRequiringAttention]);

    return {
        fillableFieldRequiringAttention,
        fillableFields,
        parsedContractHtml
    };
};

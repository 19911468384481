import React, { type ComponentProps, useEffect, useMemo } from 'react';
import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { Outlet, useLocation, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { withI18NextTranslate } from 'sp-ui';
import { getTheme } from 'ts/common/components/gallery';
import { IFetchHookResponse, useQuery } from 'ts/common/hooks';
import PageContainer from 'client_react/booking/PageContainer';
import { QUERY } from 'client_react/booking/common';
import { IBookingSessionAvailability, IBookingSessionType } from 'client_react/booking/types';
import useBookingSession from 'client_react/booking/useBookingSession';
import useContract from 'client_react/booking/useContract';
import { useBootstrapData, useClientApiFetch, withBootstrapData } from 'client_react/bootstrap';

export type ClientBookingData = ReturnType<typeof useBookingSession> &
    ReturnType<typeof useContract> & {
        bookingSessionType: IFetchHookResponse<IBookingSessionType>;
        bookingSessionTypeAvailability: IFetchHookResponse<IBookingSessionAvailability>;
    };

const App = withBootstrapData(() => {
    const navigate = useNavigate();
    const { translations } = useBootstrapData();
    const PageContainerWithTranslations = useMemo(
        () =>
            withI18NextTranslate<ComponentProps<typeof PageContainer>>(
                ({ children, ...rest }: ComponentProps<typeof PageContainer>) => (
                    <PageContainer {...rest}>{children}</PageContainer>
                ),
                translations
            ),
        [translations]
    );

    const location = useLocation();

    const query = useQuery();
    const queryBookingSessionId = query.get(QUERY.BOOKING_SESSION_ID);

    const { sessionTypeSlug, bookingSessionId: paramBookingSessionId } = useParams();

    const bookingSessionId = location.pathname.startsWith('/session')
        ? paramBookingSessionId ?? null
        : queryBookingSessionId ?? null;

    const {
        bookingSession,
        createBookingSession,
        error,
        getBookingSession,
        heartbeatBookingSession,
        isPending,
        submitPayment,
        updateBookingSession
    } = useBookingSession(bookingSessionId);

    const {
        contract,
        createContract,
        isPending: isPendingContract,
        signContract,
        updateContract
    } = useContract(bookingSession?.contractId, bookingSession?.contactEmail);

    const { response: bookingSessionType, performFetch: fetchSessionType } =
        useClientApiFetch<IBookingSessionType>('booking-session-type', {
            defer: true
        });

    const currentDate = new Date().toISOString().split('T')[0];

    const { response: bookingSessionTypeAvailability, performFetch: fetchAvailability } =
        useClientApiFetch<IBookingSessionAvailability>('', {
            defer: true
        });

    useEffect(() => {
        if (!bookingSessionId || bookingSession?.isConfirmed) {
            return;
        }

        const expiredSessionCheckTimer = setInterval(() => {
            (async () => {
                const response = await getBookingSession();

                if (!!response && response.status === 404) {
                    navigate(`/booking/${sessionTypeSlug}?expired=1`);
                }
            })();
        }, 5000);

        return () => {
            clearInterval(expiredSessionCheckTimer);
        };
    }, [bookingSession, bookingSessionId, getBookingSession, navigate, sessionTypeSlug]);

    useEffect(() => {
        if (sessionTypeSlug) {
            fetchSessionType({ url: sessionTypeSlug });
        }
    }, [fetchSessionType, sessionTypeSlug]);

    useEffect(() => {
        if (bookingSessionType?.publicId) {
            fetchAvailability({
                url: `booking-session-type/${bookingSessionType?.publicId}/availability`
            });
        }
    }, [bookingSessionType, currentDate, fetchAvailability]);

    useEffect(() => {
        if (bookingSessionId && error?.status === 404) {
            navigate(`/booking/${sessionTypeSlug}?expired=1`);
        }

        if (
            (!bookingSessionId && !sessionTypeSlug) ||
            (sessionTypeSlug && bookingSessionType?.status === 404)
        ) {
            navigate('/not-found');
        }
    }, [
        bookingSession,
        bookingSessionId,
        bookingSessionType,
        error,
        navigate,
        queryBookingSessionId,
        sessionTypeSlug
    ]);

    const brandTheme = bookingSession?.brandTheme ?? bookingSessionType?.brandTheme;

    const theme = useMemo(
        () => (brandTheme ? extendTheme(getTheme(brandTheme)) : undefined),
        [brandTheme]
    );

    const clientBookingData: ClientBookingData = useMemo(
        () => ({
            bookingSessionType,
            bookingSessionTypeAvailability,
            bookingSession,
            contract,
            createBookingSession,
            createContract,
            error: error,
            getBookingSession,
            heartbeatBookingSession,
            isPending: isPending || isPendingContract,
            signContract,
            submitPayment,
            updateBookingSession,
            updateContract
        }),
        [
            bookingSessionType,
            bookingSessionTypeAvailability,
            bookingSession,
            contract,
            createBookingSession,
            createContract,
            error,
            getBookingSession,
            heartbeatBookingSession,
            isPending,
            isPendingContract,
            signContract,
            submitPayment,
            updateBookingSession,
            updateContract
        ]
    );

    // something more elegant would be nice
    if (!theme) {
        return null;
    }

    return (
        <ChakraProvider resetCSS={false} theme={theme}>
            <PageContainerWithTranslations
                bookingSession={bookingSession}
                bookingSessionType={bookingSessionType}
            >
                <Outlet context={clientBookingData} />
            </PageContainerWithTranslations>
        </ChakraProvider>
    );
});

export function useClientBookingData() {
    return useOutletContext<ClientBookingData>();
}

export default App;

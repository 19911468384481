import React from 'react';
import { Box, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { navy600 } from 'ts/studio/getTheme';

const getTagPosition = (element: HTMLElement) => {
    if (element) {
        const elementHeight = element.offsetHeight;
        return element.offsetTop - 15 + elementHeight / 2;
    }

    return 0;
};

interface ContractActionTagProps {
    actionText: string;
    element: HTMLElement | null;
}

const ContractActionTag: React.FC<ContractActionTagProps> = ({ actionText, element }) => {
    const theme = useTheme();
    const isMobile = useBreakpointValue({ base: true, md: false });

    if (!element) {
        return null;
    }

    const tagPosition = getTagPosition(element);
    const tagColor = theme.colors.brand?.primary ?? navy600;

    return (
        <Box
            position="absolute"
            top={`${tagPosition}px`}
            right="0"
            width={isMobile ? '30px' : '128px'}
            data-testid="contract-action-tag"
        >
            <svg
                cursor="pointer"
                width={isMobile ? '30' : '128'}
                height="30"
                viewBox={isMobile ? '0 0 30 30' : '0 0 128 30'}
                fill={tagColor}
                xmlns="http://www.w3.org/2000/svg"
                onClick={() => {
                    element.scrollIntoView({ behavior: 'smooth', block: 'center' });
                    element.focus();
                }}
            >
                <path
                    d={
                        isMobile
                            ? 'M20 1C20 0.447716 20.4477 0 21 0H26C28.2091 0 30 1.79086 30 4V26C30 28.2091 28.2091 30 26 30H21C20.4477 30 20 29.5523 20 29V1Z'
                            : 'M20 1C20 0.447716 20.4477 0 21 0H124C126.209 0 128 1.79086 128 4V26C128 28.2091 126.209 30 124 30H21C20.4477 30 20 29.5523 20 29V1Z'
                    }
                    fill={tagColor}
                />
                <path
                    d="M2.42719 18.392C-0.0794835 16.8253 -0.0794833 13.1747 2.42719 11.608L21 1.90735e-06L21 30L2.42719 18.392Z"
                    fill={tagColor}
                />
                {!isMobile && (
                    <text
                        x="60%"
                        y="50%"
                        textAnchor="middle"
                        dominantBaseline="middle"
                        fill="white"
                        fontFamily="open-sans"
                        fontSize="14"
                        fontWeight="350"
                    >
                        {actionText}
                    </text>
                )}
            </svg>
        </Box>
    );
};

export default ContractActionTag;

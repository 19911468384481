import React from 'react';
import { Box, Button, Divider, Text, useBreakpointValue, useTheme } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';

interface ContractSignatureProps {
    isSignable: boolean;
    name: string;
    openSignatureModal?: () => void;
    signature?: SpApi.IContractSignature['signature'];
    translateKeyBase: string;
    filledAllRequiredFields: boolean;
}

const ContractSignature: React.FC<ContractSignatureProps> = ({
    isSignable,
    name,
    openSignatureModal,
    signature,
    translateKeyBase,
    filledAllRequiredFields
}) => {
    const t = useTranslate(translateKeyBase);
    const theme = useTheme();
    const isMobile = useBreakpointValue({ base: true, md: false });

    return (
        <Box data-testid="contract-signature">
            {signature ? (
                <svg
                    data-testid="signature-svg"
                    width={isMobile ? 'unset' : '400px'}
                    height="100"
                    viewBox={signature.svgViewbox}
                    style={{
                        maxWidth: isMobile ? 'unset' : '400px',
                        height: '100px',
                        display: 'block',
                        marginLeft: '0'
                    }}
                >
                    <path
                        d={signature.signaturePaths}
                        stroke={theme.isLightColorScheme === false ? 'white' : 'black'}
                        strokeWidth="2"
                        fill="none"
                        transform="translate(-200, 0)"
                    />
                </svg>
            ) : (
                <>
                    {isSignable ? (
                        <Button
                            id="signButton"
                            variant="unstyled"
                            border="1px solid"
                            borderColor="gray.400"
                            borderRadius="4px"
                            p={4}
                            mb={2}
                            bg="gray.200"
                            isDisabled={!filledAllRequiredFields}
                            cursor="pointer"
                            onClick={openSignatureModal}
                            width={isMobile ? '100%' : '400px'}
                            display="block"
                            textAlign="left"
                            height="auto"
                            _focus={{
                                boxShadow: 'outline',
                                outline: 'none'
                            }}
                        >
                            <Text fontSize="16px" fontWeight="350" color="gray.400">
                                {t('clickHereToSign')}
                            </Text>
                        </Button>
                    ) : (
                        <Divider
                            borderColor="neutral.400"
                            width={isMobile ? '100%' : '400px'}
                            mt={20}
                            mb={2}
                            marginLeft="0"
                        />
                    )}
                </>
            )}
            <Text fontSize="12px" fontWeight="400" color="neutral.400" mt={2}>
                {name}
            </Text>
        </Box>
    );
};

export default ContractSignature;

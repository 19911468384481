import React, { RefObject, forwardRef, useContext } from 'react';
import { Box, Flex, Input, InputGroup, InputRightElement } from '@chakra-ui/react';
import { ReactComponent as DatePickerCalendar } from 'svg/common/date-picker-calendar.svg';
import { DatePickerContext } from './DatePicker';

const DatePickerInput = forwardRef(function DatePickerInput(props, ref) {
    const { isInvalid, open } = useContext(DatePickerContext);

    return (
        <Flex ref={ref as RefObject<HTMLDivElement>} role="group">
            <InputGroup>
                <InputRightElement>
                    <Box onClick={open} cursor="pointer" padding="8px" marginLeft={undefined}>
                        <DatePickerCalendar />
                    </Box>
                </InputRightElement>
                <Input isInvalid={isInvalid} isReadOnly={true} {...props} />
            </InputGroup>
        </Flex>
    );
});

export default DatePickerInput;

import React from 'react';
import { Box, useTheme } from '@chakra-ui/react';
import { useBreakpoint } from 'ts/common/hooks';

interface BookingSessionTypeCoverProps {
    title?: string | null;
}

export const BookingSessionTypeCover: React.FC<BookingSessionTypeCoverProps> = ({ title }) => {
    const theme = useTheme();
    const fontFamily = theme.brand.fonts.primary;
    const backgroundColor = theme.brand.colors.user.primary;
    const titleTextColor = theme.brand.colors.theme.primary;
    const isMediumScreen = useBreakpoint('md');

    return (
        <Box
            background={`linear-gradient(0deg, rgba(0, 0, 0, 0.00) 0%, rgba(0, 0, 0, 0.20) 100%), ${backgroundColor}`}
            display="flex"
            width="100%"
            height="100%"
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                color={titleTextColor}
                fontFamily={fontFamily}
                fontSize="16px"
                fontStyle="normal"
                fontWeight={450}
                letterSpacing="wider"
                lineHeight="32px"
                noOfLines={3}
                paddingX={isMediumScreen ? '8px' : '4px'}
                textAlign="center"
                textShadow="0px 5px 9px rgba(0, 0, 0, 0.25)"
                textTransform="uppercase"
                title={title ?? ''}
            >
                {title ?? ''}
            </Box>
        </Box>
    );
};

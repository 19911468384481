import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Flex, useBreakpointValue, useDisclosure, useToast } from '@chakra-ui/react';
import { Contract, SignatureModal } from 'ts/common/components';
import { ButtonProps } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { usePrintOrDownloadContract } from 'ts/common/hooks/contract/usePrintOrDownloadContract';
import { useClientBookingExperience } from 'client_react/booking/ClientBookingExperience';
import { ROUTE } from 'client_react/booking/common';
import BookingSessionHeading from 'client_react/booking/components/BookingSessionHeading';
import ResponsiveButtonGroup from 'client_react/booking/components/ResponsiveButtonGroup';
import { isContractError } from 'client_react/booking/useContract';

interface ContractPageProps {
    isConfirmation?: boolean;
}

const ContractPage = ({ isConfirmation }: ContractPageProps) => {
    const {
        bookingSession,
        bookingSessionType,
        contract,
        heartbeatBookingSession: heartbeat,
        isPending,
        navigateWithSession,
        signContract,
        submitPayment,
        updateContract
    } = useClientBookingExperience();
    const { isOpen, onOpen: openSignatureModal, onClose } = useDisclosure();
    const isMobile = useBreakpointValue({ base: true, md: false });

    const [fillableFieldsData, setFillableFieldsData] = useState<SpApi.IContractFillableField[]>(
        []
    );
    const [currentFieldIndex, setCurrentFieldIndex] = useState<number>(-1);
    const [hasStartedFilling, setHasStartedFilling] = useState<boolean>(false);
    const allFillableFieldsCompleted = fillableFieldsData.every((field) => field.value !== '');
    const brandName = bookingSession?.brandName ?? bookingSessionType?.brandName ?? '';

    const t = useTranslate('booking.contractPage');

    const { printContract, downloadContract } = usePrintOrDownloadContract(
        contract?.links?.alternate?.href +
            '&timezone=' +
            Intl.DateTimeFormat().resolvedOptions().timeZone ?? ''
    );

    const errorToast = useToast({
        title: t('apiError'),
        status: 'error'
    });

    useEffect(() => {
        if (isPending) {
            return;
        }

        if (!bookingSession) {
            navigateWithSession(ROUTE.DATE_AND_TIME);
        } else if (!contract) {
            navigateWithSession(ROUTE.CONTACT_INFORMATION);
        }
    }, [bookingSession, contract, isPending, navigateWithSession]);

    const handleScrollToField = useCallback((fieldId: string) => {
        const fieldElement = document.getElementById(fieldId);

        if (fieldElement) {
            fieldElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
            fieldElement.focus();
        }
    }, []);

    const handleNextField = useCallback(() => {
        if (!hasStartedFilling) {
            setHasStartedFilling(true);
            setCurrentFieldIndex(0);
            const firstEmptyField = fillableFieldsData.findIndex((field) => field.value === '');

            if (firstEmptyField !== -1) {
                handleScrollToField(fillableFieldsData[firstEmptyField].id);
            }
        } else {
            const nextEmptyField = fillableFieldsData.findIndex(
                (field, index) => field.value === '' && index > currentFieldIndex
            );

            if (nextEmptyField !== -1) {
                setCurrentFieldIndex(nextEmptyField);
                handleScrollToField(fillableFieldsData[nextEmptyField].id);
            }
        }
    }, [hasStartedFilling, fillableFieldsData, currentFieldIndex, handleScrollToField]);

    const handlePreviousField = useCallback(() => {
        if (currentFieldIndex === 0) {
            navigateWithSession(ROUTE.CONTACT_INFORMATION);
        } else if (currentFieldIndex > 0) {
            const prevIndex = currentFieldIndex - 1;
            setCurrentFieldIndex(prevIndex);
            handleScrollToField(fillableFieldsData[prevIndex].id);
        }
    }, [currentFieldIndex, fillableFieldsData, handleScrollToField, navigateWithSession]);

    const getNextButtonText = useCallback(() => {
        if (!isMobile) {
            return t('signDocument');
        }

        if (!hasStartedFilling) {
            return t('start');
        }

        if (allFillableFieldsCompleted) {
            return t('signDocument');
        }

        return t('next');
    }, [hasStartedFilling, isMobile, allFillableFieldsCompleted, t]);

    const handleSign = async (signaturePaths: string, svgViewbox: string) => {
        heartbeat();
        const updateContractResponse = await updateContract({
            data: {
                id: contract?.publicId,
                fillableFieldData: fillableFieldsData,
                type: 'contract'
            },
            search: {
                email: contract?.contacts[0].email ?? ''
            }
        });

        if (!updateContractResponse || isContractError(updateContractResponse)) {
            errorToast();

            return;
        }

        const contractSignatureResponse = await signContract({
            data: {
                email: updateContractResponse?.contacts[0].email,
                signaturePaths: signaturePaths,
                svgViewbox: svgViewbox,
                state: updateContractResponse?.state,
                type: 'contract-signature'
            }
        });

        if (!contractSignatureResponse || isContractError(contractSignatureResponse)) {
            errorToast();

            return;
        }

        if (bookingSession?.stripePrice === 0) {
            await submitPayment({ paymentAmountType: 'full' });
            navigateWithSession(ROUTE.CONFIRMATION);
        } else {
            navigateWithSession(ROUTE.PAYMENT);
        }
    };

    const isCurrentFieldFilled = useCallback(() => {
        if (!hasStartedFilling || currentFieldIndex === -1) {
            return true; // Allow clicking Start button
        }

        return fillableFieldsData[currentFieldIndex]?.value !== '';
    }, [hasStartedFilling, currentFieldIndex, fillableFieldsData]);

    const handleContractStart = useCallback(() => {
        setHasStartedFilling(true);
        setCurrentFieldIndex(0);
    }, []);

    const buttons = useMemo<ButtonProps[]>(
        () =>
            isConfirmation
                ? [
                      {
                          text: t('download'),
                          onClick: () => {
                              heartbeat();
                              downloadContract();
                          },
                          variant: 'outline primary',
                          cursor: 'pointer'
                      },
                      {
                          text: t('print'),
                          onClick: () => {
                              heartbeat();
                              printContract();
                          },
                          variant: 'primary',
                          cursor: 'pointer'
                      }
                  ]
                : [
                      {
                          text: t('back'),
                          onClick: () => {
                              heartbeat();

                              if (isMobile && hasStartedFilling) {
                                  handlePreviousField();
                              } else {
                                  navigateWithSession(ROUTE.CONTACT_INFORMATION);
                              }
                          },
                          variant: 'outline primary',
                          cursor: 'pointer'
                      },
                      {
                          text: getNextButtonText(),
                          onClick: () => {
                              heartbeat();

                              if (isMobile) {
                                  if (allFillableFieldsCompleted) {
                                      openSignatureModal();
                                  } else {
                                      handleNextField();
                                  }
                              } else {
                                  openSignatureModal();
                              }
                          },
                          isDisabled:
                              (!allFillableFieldsCompleted && !isMobile) ||
                              (isMobile && !isCurrentFieldFilled()),
                          isLoading: false,
                          variant: 'primary',
                          type: 'submit',
                          cursor: 'pointer'
                      }
                  ],
        [
            allFillableFieldsCompleted,
            downloadContract,
            heartbeat,
            isConfirmation,
            getNextButtonText,
            handleNextField,
            handlePreviousField,
            navigateWithSession,
            openSignatureModal,
            printContract,
            t,
            isMobile,
            hasStartedFilling,
            isCurrentFieldFilled
        ]
    );

    if (!bookingSession || !bookingSessionType || !contract) {
        return null;
    }

    return (
        <>
            {!isConfirmation && (
                <BookingSessionHeading
                    bookingSession={bookingSession}
                    bookingSessionType={bookingSessionType}
                />
            )}
            <Contract
                brandName={brandName}
                contractData={contract}
                heartbeat={heartbeat}
                openSignatureModal={openSignatureModal}
                showDownloadButton
                isConfirmation={isConfirmation}
                onFillableFieldsChange={setFillableFieldsData}
                onStart={handleContractStart}
            />
            <Flex justifyContent="center" mt={8}>
                <ResponsiveButtonGroup buttons={buttons} />
            </Flex>

            <SignatureModal
                isOpen={isOpen}
                onClose={onClose}
                onAcceptAndSign={(signaturePaths, svgViewbox) => {
                    handleSign(signaturePaths, svgViewbox);
                    onClose();
                }}
            />
        </>
    );
};

export default ContractPage;

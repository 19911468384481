import React, { ForwardRefRenderFunction, forwardRef } from 'react';
import { Button, ButtonProps } from '@chakra-ui/react';
import { navy600 } from 'ts/studio/getTheme';

interface Props extends ButtonProps {
    /** Primary buttons use a darker background with light text, while secondary buttons use the inverse */
    variant?: 'primary' | 'outline primary' | 'secondary' | 'destructive';
}

const buttonColors = {
    primary: {
        color: 'white',
        backgroundColor: navy600,
        borderColor: navy600,
        hoverBackgroundColor: navy600
    },
    'outline primary': {
        color: navy600,
        backgroundColor: 'transparent',
        borderColor: navy600,
        hoverBackgroundColor: 'var(--chakra-colors-gray-200)'
    },
    secondary: {
        color: navy600,
        backgroundColor: 'transparent',
        borderColor: 'transparent',
        hoverBackgroundColor: 'var(--chakra-colors-gray-200)'
    },
    destructive: {
        color: 'white',
        backgroundColor: 'red.500',
        borderColor: 'red.500',
        hoverBackgroundColor: 'red.500'
    }
};

/**
 * A modern button for the refreshed post-sp-ui Studio UI design system.
 */
const ModernButton: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
    { variant = 'primary', children, ...rest },
    ref
) => {
    const { color, backgroundColor, borderColor, hoverBackgroundColor } = buttonColors[variant];

    return (
        <Button
            ref={ref}
            type="button"
            color={color}
            cursor="pointer"
            backgroundColor={backgroundColor}
            border={`1px solid ${borderColor}`}
            height="36px"
            fontFamily="'azo-sans-web', 'Helvetica', 'Arial', sans-serif"
            fontSize="12px"
            lineHeight="18px"
            borderRadius="8px"
            _hover={{
                backgroundColor: hoverBackgroundColor,
                color: color,
                '&:disabled': { backgroundColor }
            }}
            _focus={{
                boxShadow: 'none'
            }}
            _focusVisible={{
                boxShadow: 'outline',
                '&:disabled': { backgroundColor }
            }}
            _active={{
                '&:disabled': { backgroundColor }
            }}
            {...rest}
        >
            {children}
        </Button>
    );
};

export default forwardRef(ModernButton);

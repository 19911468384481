import React, { useCallback, useMemo, useState } from 'react';
import { Flex, Heading, Hide, Show, useToast } from '@chakra-ui/react';
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ButtonProps } from 'ts/common/components/gallery';
import { useTranslate } from 'ts/common/hooks';
import { useClientBookingExperience } from 'client_react/booking/ClientBookingExperience';
import { ROUTE } from 'client_react/booking/common';
import PaymentOptions from 'client_react/booking/components/PaymentOptions';
import PaymentSummary from 'client_react/booking/components/PaymentSummary';
import ResponsiveButtonGroup from 'client_react/booking/components/ResponsiveButtonGroup';
import {
    isBookingSessionError,
    isBookingSessionRequiresActionResponse
} from 'client_react/booking/useBookingSession';

const PaymentForm = ({
    paymentAmountType,
    setPaymentAmountType
}: {
    paymentAmountType: 'full' | 'retainer';
    setPaymentAmountType: (x: string) => void;
}) => {
    const { bookingSession, isPending, navigateWithSession, submitPayment } =
        useClientBookingExperience();

    const t = useTranslate('booking.payment');
    const stripe = useStripe();
    const elements = useElements();

    const errorToast = useToast({
        status: 'error'
    });

    const [isReadyToPay, setIsReadyToPay] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const handlePaymentFormReady = useCallback(() => {
        setIsReadyToPay(true);
    }, []);

    const handleSubmit = useCallback(
        async (
            event: React.FormEvent | { paymentIntentId: string } | { setupIntentId: string }
        ) => {
            if ('preventDefault' in event) {
                event.preventDefault();
            }

            if (!stripe || !elements) {
                return;
            }

            setIsSubmitting(true);

            const paymentIntentId = 'paymentIntentId' in event ? event.paymentIntentId : undefined;
            const setupIntentId = 'setupIntentId' in event ? event.setupIntentId : undefined;
            let paymentMethodId: string | undefined;

            if (!paymentIntentId && !setupIntentId) {
                const { error: elementsError } = await elements.submit();

                if (elementsError) {
                    setIsSubmitting(false);
                    errorToast({
                        title: elementsError.message
                    });
                    return;
                }

                const paymentMethodResponse = await stripe.createPaymentMethod({
                    elements
                });

                if (paymentMethodResponse.error) {
                    setIsSubmitting(false);
                    errorToast({
                        // TODO Make sure this error message is user-friendly
                        title: paymentMethodResponse.error.message
                    });
                    return;
                }

                paymentMethodId = paymentMethodResponse.paymentMethod.id;
            }

            const submitPaymentResponse = await submitPayment({
                paymentAmountType,
                paymentIntentId,
                paymentMethodId,
                setupIntentId
            });

            if (isBookingSessionRequiresActionResponse(submitPaymentResponse)) {
                // This payment intent requires user verification (3D Secure, etc.)
                // So we need to handle that and send the confirmed payment intent id back to our payment endpoint.
                // The API error response detail property is a string that looks like this
                // requires_action:pi_xxxxxxxxxxxxxxxxxxxxxxxx_secret_xxxxxxxxxxxxxxxxxxxxxxxxx
                const clientSecret = submitPaymentResponse.detail.substring(
                    'requires_action:'.length
                );

                if (!(clientSecret.startsWith('pi_') || clientSecret.startsWith('seti_'))) {
                    setIsSubmitting(false);
                    errorToast({
                        // TODO Show a more specific or accurate error message
                        title: t('error.cardDeclined')
                    });
                    return;
                }

                const { error, paymentIntent, setupIntent } = await stripe.handleNextAction({
                    clientSecret
                });

                if (
                    error ||
                    (paymentIntent && paymentIntent.status !== 'succeeded') ||
                    (setupIntent && setupIntent.status !== 'succeeded')
                ) {
                    setIsSubmitting(false);
                    errorToast({
                        // TODO Show a more specific or accurate error message
                        title: t('error.cardDeclined')
                    });
                } else if (paymentIntent) {
                    // Resubmit the payment with the confirmed paymentIntentId:
                    await handleSubmit({
                        paymentIntentId: paymentIntent.id
                    });
                } else if (setupIntent) {
                    // Resubmit the payment with the confirmed setupIntentId:
                    await handleSubmit({
                        setupIntentId: setupIntent.id
                    });
                }
            } else if (isBookingSessionError(submitPaymentResponse)) {
                setIsSubmitting(false);
                errorToast({
                    // TODO Show a more specific or accurate error message
                    title: t('error.cardDeclined')
                });
            } else {
                navigateWithSession(ROUTE.CONFIRMATION);
            }
        },
        [elements, errorToast, navigateWithSession, paymentAmountType, stripe, submitPayment, t]
    );

    const buttons = useMemo<Array<ButtonProps>>(
        () => [
            {
                text: t('back'),
                variant: 'outline primary',
                onClick: () => {
                    if (bookingSession?.contractId) {
                        navigateWithSession(ROUTE.CONTRACT);
                    } else {
                        navigateWithSession(ROUTE.CONTACT_INFORMATION);
                    }
                }
            },
            {
                text: t('bookSession'),
                variant: 'primary',
                isDisabled: !stripe || !elements || !isReadyToPay || isPending || isSubmitting,
                isLoading: isSubmitting,
                type: 'submit'
            }
        ],
        [elements, isPending, isReadyToPay, isSubmitting, navigateWithSession, stripe, t]
    );

    return (
        <Flex
            as="form"
            alignItems="stretch"
            flexDirection="column"
            gap="40px"
            onSubmit={handleSubmit}
        >
            <Flex gap="24px 64px">
                <Flex flex="1 1 auto" flexDirection="column" gap="24px 64px">
                    <PaymentOptions
                        paymentAmountType={paymentAmountType}
                        setPaymentAmountType={setPaymentAmountType}
                    />
                    <Hide above="lg">
                        <PaymentSummary paymentAmountType={paymentAmountType} />
                    </Hide>
                    <Flex flexDirection="column" gap="16px">
                        <Heading as="h3" size="lg" margin={0}>
                            {t('billingInformation')}
                        </Heading>
                        <PaymentElement onReady={handlePaymentFormReady} />
                    </Flex>
                </Flex>
                <Show above="lg">
                    <PaymentSummary paymentAmountType={paymentAmountType} />
                </Show>
            </Flex>
            <ResponsiveButtonGroup buttons={buttons} marginTop="32px" />
        </Flex>
    );
};

export default PaymentForm;

import { useFetch } from '../fetch';

interface UsePrintOrDownloadContractReturn {
    printContract: () => Promise<void>;
    downloadContract: () => void;
}

export const usePrintOrDownloadContract = (
    contractUrl: string
): UsePrintOrDownloadContractReturn => {
    const { performFetch: fetchPdf } = useFetch<{ response: Response }>(contractUrl, {
        defer: true,
        headers: {
            'Content-Type': 'application/pdf'
        }
    });

    const printContract = async () => {
        const response = await fetchPdf();

        if (!response) {
            return;
        }

        const pdfBlob = await response.response.blob();
        const pdfUrl = URL.createObjectURL(pdfBlob);

        const printWindow = window.open(pdfUrl);
        printWindow?.addEventListener('load', () => {
            printWindow.print();
            URL.revokeObjectURL(pdfUrl);
        });
    };

    const downloadContract = () => {
        window.open(contractUrl, '_blank');
    };

    return {
        printContract,
        downloadContract
    };
};

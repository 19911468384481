import React from 'react';
import { Flex, Heading, Text } from '@chakra-ui/react';
import { useTranslate } from 'ts/common/hooks';
import { replaceHtmlEscapeCharacters } from 'ts/common/utils';
import BookingSessionSummary from 'client_react/booking/components/BookingSessionSummary';
import { IBookingSession, IBookingSessionType } from 'client_react/booking/types';

type Props = {
    bookingSession: IBookingSession | null;
    bookingSessionType: IBookingSessionType | null;
    contract?: SpApi.Client.IContract | null;
    showSubtitle: boolean;
    onViewContract?: () => void;
};

const ConfirmationContent = ({
    bookingSession,
    bookingSessionType,
    showSubtitle,
    contract,
    onViewContract
}: Props) => {
    const t = useTranslate('booking.confirmationPage');

    const images = bookingSession?.images ?? bookingSessionType?.images;

    const requiresApproval =
        bookingSession?.requiresApproval || bookingSessionType?.requiresApproval;

    return (
        <Flex direction="column" gap="16px" marginBottom="24px">
            {images && images.length > 0 && (
                <Heading as="h1" margin={0} size={{ base: '2xl', lg: '3xl' }} color="brand.primary">
                    {t('title')}
                </Heading>
            )}
            {showSubtitle && (
                <Text fontSize={{ base: 'md', lg: 'lg' }}>
                    {replaceHtmlEscapeCharacters(
                        t(requiresApproval ? 'needsConfirmationSubtitle' : 'confirmedSubtitle', {
                            brandName: bookingSession?.brandName
                        })
                    )}
                </Text>
            )}
            <BookingSessionSummary
                bookingSessionType={bookingSessionType}
                bookingSession={bookingSession}
                contract={contract}
                isConfirmation
                onViewContract={onViewContract}
            />
            {bookingSessionType?.description && (
                <Text marginTop="8px">{bookingSessionType.description}</Text>
            )}
        </Flex>
    );
};

export default ConfirmationContent;
